.admin-grid {
  display: grid !important;
  grid-template-columns: 1fr 0.8fr 1fr 0.2fr 1fr 0.2fr 1fr;
  grid-template-rows: 15% 1.2fr 1fr 1fr 1fr 0.2fr 1fr 1fr 0.2fr 1fr;
  width: 100%;
  height: 85vh !important;
  grid-template-areas:
    "engagement engagement cfm cfm cfm notification notification"
    "engagement engagement cfm cfm cfm notification notification"
    "leaveSummary leaveSummary cfm cfm cfm notification notification"
    "leaveSummary leaveSummary cfm cfm cfm notification notification"
    /* "leaveSummary leaveSummary cfm cfm cfm notification notification" */
    "leaveSummary leaveSummary cfm cfm cfm todoList todoList"
    "transferApproval transferApproval transferApproval transferApproval transferApproval todoList todoList"
    "transferApproval transferApproval transferApproval transferApproval transferApproval todoList todoList"
    "transferApproval transferApproval transferApproval transferApproval transferApproval todoList todoList"
    "transferApproval transferApproval transferApproval transferApproval transferApproval todoList todoList"
    "transferApproval transferApproval transferApproval transferApproval transferApproval todoList todoList";
  grid-gap: 24px;
}

.engagement {
  grid-area: engagement !important;
}

.leaveSummary {
  grid-area: leaveSummary;
}

.transferApproval {
  grid-area: transferApproval;
}

.cfm {
  grid-area: cfm;
}

.notification {
  grid-area: notification;
}

.todoList {
  grid-area: todoList;
}

.graph {
  margin-top: -15%;
  height: 127px;
  width: 127px;
}

.vericalDevider {
  height: 78px;
  border: 1px solid #cbd5e1;
  transform: rotate(180deg);
}

.leave-addon {
  margin-left: 5px !important;
  margin-right: 8px !important;
  display: flex;
  flex-direction: column;
}

.noData-Img {
  height: 75px;
  width: 75px;
}
/* ----------------Leave Summary-------------------------- */
.leaveSummaryContainer {
  display: flex;
  flex-direction: row;

  align-items: center;
  overflow-x: auto; /* Changed to auto for better compatibility */
  overflow-y: hidden;
  width: 100%; /* Adjusted to 100% */
  padding: 8px;
}

.content-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 6vw;
  text-align: center;
  justify-content: center;
  height: 100%;
}

.leave-pinkColor,
.leave-blueColor,
.leave-redColor,
.leave-lightGreenColor,
.leave-yellowColor,
.leave-darkPinkColor {
  flex: 1; /* Equal width for all elements */
  max-width: 200px; /* Maximum width for each element */
  height: 80px;
  border-radius: 26px 26px 6px 6px;
}

.bottom-Border-pink,
.bottom-Border-green,
.bottom-Border-red,
.bottom-Border-light,
.bottom-Border-yellow,
.bottom-Border-darkpink {
  flex: 1; /* Equal width for all elements */
  max-width: 200px; /* Maximum width for each element */
  height: 5px;
  border-radius: 0px 0px 6px 6px;
  transform: rotate(180deg);
}

.leave-pinkColor {
  background: linear-gradient(90deg, rgba(217, 130, 245, 0.2) 0%, rgba(168, 17, 218, 0.2) 100%);
}

.leave-blueColor {
  background: linear-gradient(90deg, rgba(165, 236, 125, 0.2) 0%, rgba(118, 184, 82, 0.2) 100%);
}

.leave-redColor {
  background: linear-gradient(90deg, rgba(255, 147, 147, 0.2) 0%, rgba(236, 0, 42, 0.2) 100%);
}

.leave-lightGreenColor {
  background: linear-gradient(90deg, #a8fc6c 0%, #a6ff66 100%);
}

.leave-darkPinkColor {
  background: linear-gradient(90deg, #fb83d7 0%, #ff1aba 100%);
}

.leave-yellowColor {
  background: linear-gradient(90deg, #fbfb67 0%, #ffff66 100%);
}

.bottom-Border-pink {
  background: linear-gradient(90deg, #d982f5 0%, #a811da 100%);
}

.bottom-Border-green {
  background: linear-gradient(90deg, #3cb106 0%, #3daf00 100%);
}

.bottom-Border-red {
  background: linear-gradient(90deg, #ff9393 0%, #ec002a 100%);
}

.bottom-Border-light {
  background: linear-gradient(90deg, #88ff33 0%, #a6ff66 100%);
}

.bottom-Border-yellow {
  background: linear-gradient(90deg, #fdc705 0%, #fbc604 100%);
}

.bottom-Border-darkpink {
  background: linear-gradient(90deg, #cc008f 0%, #ff1aba 100%);
}

/* ------------------------------------------------------------------- */
.div-flex {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: nowrap;
  position: relative;
  height: auto;
}

.welcomePage {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 36rem !important;

  font-family: "UniNeue-Bold", Sans-serif;
  font-style: normal;
  font-weight: 800;
  font-size: 1.5rem;
  line-height: 1.188rem;
  color: #1e293b;
}

.h-30vh {
  height: 30vh !important;
}

.adminPickerScroller {
  overflow-y: scroll;
  height: 30vh !important;
}

.widthMonth {
  width: 30vh !important;
}

.marginLeftBorder {
  margin-left: 10px !important;
}

@media (max-width: 1400px) {
  .leaveSummaryContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px !important;
    width: 100%;
  }

  .leave-redColor {
    height: 65px;
    width: 90px;
    background: linear-gradient(90deg,
        rgba(255, 147, 147, 0.2) 0%,
        rgba(236, 0, 42, 0.2) 100%);
    border-radius: 26px 26px 6px 6px;
  }

  .leave-pinkColor {
    height: 65px;
    width: 90px;
    background: linear-gradient(90deg,
        rgba(217, 130, 245, 0.2) 0%,
        rgba(168, 17, 218, 0.2) 100%);
    border-radius: 26px 26px 6px 6px;
  }

  .leave-blueColor {
    height: 65px;
    width: 90px;
    background: linear-gradient(90deg,
        rgba(165, 236, 125, 0.2) 0%,
        rgba(118, 184, 82, 0.2) 100%);
    border-radius: 26px 26px 6px 6px;
  }

  .bottom-Border-pink {
    position: relative;
    height: 5px;
    width: 80px;
    background: linear-gradient(90deg, #d982f5 0%, #a811da 100%);
    border-radius: 0px 0px 6px 6px;
    transform: rotate(180deg);
    bottom: 10px;
  }

  .bottom-Border-green {
    position: relative;
    height: 5px;
    width: 80px;
    background: linear-gradient(90deg, #a5ec7d 0%, #76b852 100%);
    border-radius: 0px 0px 6px 6px;
    transform: rotate(180deg);
    bottom: 10px;
  }

  .bottom-Border-red {
    position: relative;
    height: 5px;
    width: 80px;
    background: linear-gradient(90deg, #ff9393 0%, #ec002a 100%);
    border-radius: 0px 0px 6px 6px;
    transform: rotate(180deg);
    bottom: 10px;
  }

  .marginLeftBorder {
    margin-left: 6px !important;
  }

}

@media (max-width: 1200px) {
  .admin-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 85vh;
    grid-template-areas:
      "engagement"
      "transferApproval"
      "cfm"
      "todoList"
      "notification"
      "leaveSummary";
  }

  .leaveSummaryContainer {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 0px !important;
  }

  .leave-redColor {
    height: 70px;
    width: 90px;
    background: linear-gradient(90deg,
        rgba(255, 147, 147, 0.2) 0%,
        rgba(236, 0, 42, 0.2) 100%);
    border-radius: 26px 26px 6px 6px;
  }

  .leave-pinkColor {
    height: 70px;
    width: 90px;
    background: linear-gradient(90deg,
        rgba(217, 130, 245, 0.2) 0%,
        rgba(168, 17, 218, 0.2) 100%);
    border-radius: 26px 26px 6px 6px;
  }

  .leave-blueColor {
    height: 70px;
    width: 90px;
    background: linear-gradient(90deg,
        rgba(165, 236, 125, 0.2) 0%,
        rgba(118, 184, 82, 0.2) 100%);
    border-radius: 26px 26px 6px 6px;
  }
}

.engagementCircle {
  margin-top: -2rem !important;
}

.admin-notification-circle-shape {
  width: 2.5rem;
  height: 2.5rem;
  box-sizing: border-box;
  border-radius: 40px;
  text-align: center;
  margin-right: 0.625rem;
  padding: 3.5px;
}

.admin-notification-emp_circle_text {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  text-align: center;
  line-height: 2.188rem;
  color: #FFFF;
}

.admin-notification-Icons {
  height: 20px;
  width: 20px;
}

.admin-notification-data {
  padding-top: 0.313rem;
  padding-left: 0.8rem;
}

.admin-landing-notification {
  height: 32vh;
  width: 43vh;
  overflow-y: auto;
}

.admin-landing-notification-withoutData {
  height: auto;
}