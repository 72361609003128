/* do not remove important  */
.row {
  margin-left: 0px !important;
  margin-right: 0px !important;
}

.containerpadding {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100vh;
}

.container div {
  width: 100%;
  margin-bottom: 20px;
}

.container div:last-of-type {
  margin-bottom: 0;
  /* So that spacing is even at bottom and top of container */
}

.last {
  bottom: 0px;
  display: flex;
  padding-inline-start: 0px;
  flex-direction: column;
  height: 88px;
}

.inside-div-list {
  overflow-y: auto;
  overflow-x: hidden;
}

.inside-div-list ul {
  list-style: none;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-inline-start: 0px;
}

.logoicon {
  margin-top: 0.8rem;
  margin-bottom: 1.5rem;
  width: 37px;
  height: 31px;
}

/* button css */
.common-btn {
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  border-radius: 12px;
  border: none;
}

.cancelBtn {
  color: #1E293B;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #94A3B8;
  padding: 5px 20px;
  background-color: #FFF;
  font-weight: 700;
  float: right;
}

.validateBtn {
  color: #FFF;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #94A3B8;
  padding: 5px 20px;
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  font-weight: 400;
  float: right;
}

.validateBtnDisabled {
  color: #FFF;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #94A3B8;
  padding: 5px 20px;
  background: linear-gradient(90deg, #a190e4 0%, #9d7ae2 100%);
  font-weight: 400;
  float: right;
}

.common-save-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  width: 97px;
  height: 40px;
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  border-radius: 12px;
}

.pointer {
  cursor: pointer !important;
}

.scroller {
  /* float: right; */
  overflow-y: auto;
  height: 8px;
}

.required:after {
  content: " *";
  color: red;
}

.sm-scroll-table {
  overflow-y: auto !important;
  height: 83% !important;
}

.lg-scroll-table {
  overflow-y: auto !important;
  height: 70% !important;
}

.bottomfix {
  display: flex;
  position: absolute;
  bottom: 0;
  width: 100%;
}

.effect {
  /* max-width: 400px; */
  /* min-height: 200px; */
  /* padding: 10px; */
  /* background-color: whitesmoke; */
  /* position: fixed; */
  /* left: 0; */
  /* right: 0; */
  /* margin: 0 auto; */
  /* top: -250px; */
  /* transition: top 0.5s ease-out; */
  transition: cubic-bezier(0.23, 1, 0.320, 1);
  transition-duration: 500ms;
  z-index: 1;

}

.pincode-input-text {
  border-radius: 10px !important;
  margin: 12px !important;
  border: 1px solid #3D1D76 !important;
}

.sendWishBtn {
  color: #FFF;
  text-align: center;
  border-radius: 18px;
  border: 1px solid #94A3B8;
  padding: 3px 20px;
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  font-weight: 400;
  float: right;
}

.disableBtn {
  color: #FFF;
  text-align: center;
  border-radius: 8px;
  border: 1px solid #94A3B8;
  padding: 5px 20px;
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  opacity: 0.5;
  font-weight: 400;
  float: right;
}

.no-border {
  border: 0px !important;
}

.go-to-Button {
  margin-left: 10px;
  height: 3rem;
  width: 3rem;
}

.background-highlighter {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px 20px 12px 20px;
  background: #E2E8F0;
  border-radius: 6px;
  line-height: 20px;
  color: #1E293B;
}

.background-prop{
  padding: 12px 20px 12px 20px;
  background: #E2E8F0;
  border-radius: 6px;
}

.background-prop-new{
  padding: 12px 12px 12px 20px;
  background: #E2E8F0;
  border-radius: 6px;
}

.bold-font{
  font-size: 0.85rem;
  font-weight: 900;
  color: #1e293b;
}

.button-hover-effect{
  cursor: pointer;
}