.dropdown-item {
    padding-left: 20px;
    color: #1E293B;
    font-family: "UniNeue-Regular";
    font-weight: 500;
    font-size: 0.875rem;
    font-style: normal;
    border-bottom: 0.5px solid #E4E4E4;
}

.common-dropdown-text {
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB;
    border-radius: 10px;
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
    padding: 10px;
    outline: none;
    font-family: "UniNeue-Regular";
    font-weight: 800;
    color: #1E293B;
}

.invalidDropdown {
    background-color: #FBF2F2;
    border: 1px solid #F2F4FB;
    border-radius: 10px;
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    box-shadow: inset 2px 2px 4px #EFC2C2, inset -1px -1px 2px #EDF2FF;
    padding: 10px;
    outline: none;
    font-family: "UniNeue-Regular";
    font-weight: 800;
    color: #1E293B;
}

.common-white-dropdown-text {
    background-color: #FFFF;
    border: 1px solid #F2F4FB;
    border-radius: 10px;
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
    padding: 10px;
    outline: none;
    font-family: "UniNeue-Regular";
    font-weight: 800;
    color: #1E293B;
}

.inptt {
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB;
    border-radius: 8px;
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    padding: 7px;
    outline: none;
    font-family: "UniNeue-Regular";
    font-weight: 700;
    color: #1E293B;
}

.dropdown-item:hover {
    background-color: transparent;
    border: transparent;
    cursor: pointer;
}

.dropdown-menu {
    background-color: #F2F4FB;
    border-radius: 0.938rem;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    overflow-y: auto;
    /* height: 20vh;  */
    width: -webkit-fill-available;
    /* min-width: 8rem !important; */
}

/* .dropdown-menu-for-reasoncode {
   width: 30% !important;
} */
/* .admin-dropdown-menu {
    background-color: #F2F4FB;
    border-radius: 0.938rem;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    overflow-y: auto;
    width: -webkit-fill-available;
} */

.scroller {
    overflow-y: auto;
    overflow-x: hidden;
    /* height: 150px; */
    /* height: 22vh; */
    min-height: 15vh;
    max-height: 30vh;
    width: max-content;
}

.admin-scroller {
    overflow-y: auto;
    overflow-x: hidden;
    min-height: 15vh;
    max-height: 30vh;
    width: 100%;
    /* width: max-content; */
}

.contentWise-scroll {
    overflow-y: auto;
    overflow-x: hidden;
    /* min-height: 15vh; */
    max-height: 30vh;
    width: 100%;
    /* width: max-content; */
}

.dropdownIcon {
    height: 0.488rem;
}

.menu-height {
    height: 200px;
}

.Notification-menu-height {
    height: 16vh;
}

.Notification-menu-height-Large {
    height: 22vh;
}
.max-dropdown-height {
    height: auto !important;
}

.css-qc6sy-singleValue {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem !important;
    line-height: 140%;
    color: #64748B;
}

.css-1s2u09g-control .css-ax3k5r-control {
    height: 48px;
    width: 100%;
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.css-ax3k5r-control {
    height: 44px;
    width: 100%;
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.dropdown-hnew {
    height: 20vh !important;
}

.widthwith {
    width: 30px;
}

.contentWise-scroll-min {
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 18vh;
    width: 100%;
}

.dropdown-item {
    padding: 8px 12px; /* Add some padding for a better touch area */
    cursor: pointer; /* Pointer cursor on hover */
}

.dropdown-item.highlighted {
    background-color: #cce1ff; /* Change to your preferred highlight color */
    color: #1E293B; /* Change text color if needed */
}
