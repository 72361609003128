.wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 0.5fr;
  grid-template-rows: 1fr 0.2fr 1fr 1fr 1fr;
  grid-auto-rows: minmax(150px, auto);
  width: 100%;
  height: 85vh;
  grid-template-areas:
    "approve approve announcement announcement explore explore"
    "approve approve announcement announcement todo todo"
    "doc leave announcement announcement todo todo"
    "doc leave announcement announcement todo todo"
    "appCenter appCenter appCenter appCenter appCenter appCenter";
  grid-gap: 24px;
}

.m-approve {
  grid-area: approve !important;
}

.m-announcement {
  grid-area: announcement;
}

.m-explore {
  grid-area: explore;
}

.m-todo {
  grid-area: todo;
}

.m-document {
  grid-area: doc;
}

.m-leave {
  grid-area: leave;
}

.m-appCenter {
  grid-area: appCenter;
  padding-bottom: 0px !important;
}

.table thead th {
  vertical-align: baseline !important;
}

.table-landing-heading {
  font-size: 0.8rem !important;
}

.scroller-managerApproval {
  height: 50vh !important;
  overflow-y: scroll;
}

.approveBottom {
  border-radius: 6px;
}

.nodataDiv {
  left: 50% !important;
  position: relative !important;
}

.noDataApproval {
  height: 85px;
  width: 85px;
}

.manager-leave-scroll {
  overflow-y: auto;
  height: 12vh !important;
}

/* -------leave card-------- */
.circle-color-mg {
  background: linear-gradient(225deg, #4B83D9 15.43%, #6881D7 46.89%, #8C7ED4 85.71%);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  margin-left: auto;
}

.movetoleft-mg {
  margin-left: 19px;
  margin-top: -60px;
}

.progress {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}

.space {
  width: 40%;
  margin-top: 5px;
}

.title-center {
  text-align: center;
  margin: 50px;
}

@media (max-width: 1300px) {
  .wrapper-grid {
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 185vh;
    grid-template-areas:
      "approve announcement"
      "approve announcement"
      "todo todo"
      "explore explore"
      "doc leave"
      "appCenter appCenter"
  }

  .main-contain-child {
    height: 79% !important;
  }
}

@media (min-width: 1200px) {
  .wrapper-grid {
    grid-template-columns: 1fr;
    grid-template-rows: 1.5fr 0.5fr 1fr 1fr 0.5fr;
    width: 100%;
    /* margin-left: 3rem; */
    height: 156vh;
    grid-template-areas:
      "approve explore"
      "approve todo"
      "announcement doc"
      "announcement leave"
      "appCenter appCenter"
  }

  .main-contain-child {
    height: 79% !important;
  }
}

@media screen and (max-width: 768px) and (min-width: 160px) {
  .wrapper-grid {
    grid-template-columns: 1fr !important;
    grid-template-rows: repeat(7, 1fr) !important;
    width: 100%;
    height: 88vh;
    grid-template-areas:
      "approve"
      "announcement"
      "explore"
      "todo"
      "doc"
      "leave"
      "appCenter" !important;

  }

  .marginRight-18rem {
    margin-right: 18rem !important;
  }

}

@media (min-width: 1300px) {
  .wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1.5fr 1fr 0.5fr;
    grid-template-rows: 1fr 0.2fr 1fr 1fr 17px;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    height: 30rem !important;
    grid-template-areas:
      "approve approve announcement announcement explore explore"
      "approve approve announcement announcement todo todo"
      "doc leave announcement announcement todo todo"
      "doc leave announcement announcement todo todo"
      "appCenter appCenter appCenter appCenter appCenter appCenter";
  }
}

.root {
  flex: 1;
  align-items: center;
  justify-content: center;
}

.container123 {
  height: 30px;
  width: 30px;
  border-radius: 10px;
  padding: 16px;
  border-width: 8px;
  border-color: #6881D7;
}

.circleBg {
  background: linear-gradient(225deg, #4B83D9 15.43%, #6881D7 46.89%, #8C7ED4 85.71%);
  border-radius: 50%;
  width: 54px;
  height: 54px;
  border: 4px solid #ffffff;
}

.nodata-incenter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  /* horizontally center */
  align-items: center;
  /* vertically center */
  height: 200px;
}

.new-height {
  height: 100% !important;
}

.landing-document-height {
  height: 20vh;
  text-align: center;
  justify-content: center;
}

.mt_25px {
  margin-top: -25px;
}

/* Ripple magic */
.ripple {
  position: relative;
  overflow: hidden;
}

.ripple:after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 5px;
  height: 5px;
  background: rgba(255, 255, 255, 0.837);
  opacity: 0;
  border-radius: 100%;
  transform: scale(1, 1) translate(-50%);
  transform-origin: 50% 50%;
}

@keyframes ripple {
  0% {
    transform: scale(0, 0);
    opacity: 1;
  }

  20% {
    transform: scale(25, 25);
    opacity: 1;
  }

  100% {
    opacity: 0;
    transform: scale(40, 40);
  }
}

.ripple::after {
  animation: ripple 1.5s ease-out infinite;
}

.label {
  float: right;
  padding: 6px 15px;
  background: #D6002A;
  color: #FFF;
  font-weight: bold;
  text-shadow: 1px 1px #000;
  text-transform: uppercase;
}

/* Assuming your icon elements have specific class names */

/* Leave Icon */
.toggleStyle[data-tooltip]:hover::after {
  content: attr(data-tooltip);
  display: block;
  position: absolute;
  background: #FFFFFF;
  border-radius: 14px;
  font-size: 13px;
  font-style: normal;
  font-family: 'UniNeue-Bold', Sans-serif;
  filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
  color: #3D1D76;
  padding: 0.5rem;
  margin-top: -2.6rem;
  /* Adjust the positioning as needed */
  z-index: 1;
}

/* Previous Leave Icon */
.rightGIF:hover+.toggleStyle[data-tooltip="Previous Leave"]:hover::after {
  content: attr(data-tooltip);
  display: block;
  position: absolute;
  background: #FFFFFF;
  border-radius: 14px;
  font-size: 13px;
  font-style: normal;
  font-family: 'UniNeue-Bold', Sans-serif;
  filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
  color: #3D1D76;
  padding: 0.5rem;
  margin-top: -2.6rem;
  /* Adjust the positioning as needed */
  z-index: 1;
}

/* Esep Icon */
.toggleStyle[data-tooltip="Esep"]:hover+.toggleStyle[data-tooltip="Esep"]:hover::after {
  content: attr(data-tooltip);
  display: block;
  position: absolute;
  background: #FFFFFF;
  border-radius: 14px;
  font-size: 13px;
  font-style: normal;
  font-family: 'UniNeue-Bold', Sans-serif;
  filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
  color: #3D1D76;
  padding: 0.5rem;
  margin-top: -2.6rem;
  /* Adjust the positioning as needed */
  z-index: 1;
}