.m-calendar {
    width: '100%';
    margin-top: -1.5em !important;
    padding-bottom: -1.5rem !important;
    font-size: .6em !important;
    color: #AEAEAE;
    border: white;
    font-weight: bolder !important;
    font-family: "UniNeue-Regular" !important;
    height: '100%' !important;
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9;
    background-blend-mode: soft-light, normal;
    border-radius: 90;
    overflow-y: auto;
    height: 210px;
}

.m-calendar-tile {
    line-height: 1.3em;
    font-size: 12px !important;
    padding: 0.5em !important;
}
.rbc-calendar *, .rbc-calendar *:before, .rbc-calendar *:after {
    font-size: 12px;
    font-family: 'UniNeue-Regular';
    font-style: normal;
}
.react-calendar__navigation {
    height: 30px;
    font-size: 9px;
    margin-left: 6%;
    margin-right: 6%;
    margin-bottom: 2em;
}

.scroll-calendar {
    height: '100%' !important;
    overflow-y: auto;
    overflow-x: hidden;
}