/* font size 18px and line 24px weight 400*/
.edit-cardtitle {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5rem;
    margin-top: 10px;
}

.document-common-heading {
    font-family: 'UniNeue-Regular', Sans-serif !important;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem !important;
}

.mom-common-heading {
    font-family: 'UniNeue-Regular', Sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem !important;
}

.mom-common-wishText {
    font-family: 'UniNeue-Regular', Sans-serif !important;
    font-style: normal;
    font-weight: 900;
    font-size: 2.125rem !important;
}

.appcenter-app-name {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.188rem;
    margin-top: 9px;
    color: #1E293B;
    justify-content: center;
}

.form-popup-value {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.188rem;
    color: #1E293B;
    justify-content: center;
}
.form-popup-value-65 {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-size: 0.65rem;
    line-height: 1.188rem;
    color: #030303;
    justify-content: center;
}

.appcenter-app-name1 {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
    margin-top: 9px;
    color: #1E293B;
    justify-content: center;
}

.choice-pay-table-rows {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.9rem;
    line-height: 1.181rem;
}

/* ******************** performance-timeline *******************************/
.performance-timeline-text-bold {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.181rem;
}

/* font size 14px and line 19px weight 400*/
.performance-timeline-text-lighter {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.181rem;
}

.performance-timeline-text-circle {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.181rem;
}

/* font size 12px and line 16px weight 700    date or time */
.performance-timeline-text-date {
    font-family: 'UniNeue-Regular', Sans-serif !important;
    font-style: normal !important;
    font-weight: 700 !important;
    font-size: 0.75rem !important;
    line-height: 1rem !important;
}

/* font size 14px and line 19px weight 700*/
.card-content-title {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

/* font size 14px and line 19px weight 400*/
.card-content-title1 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

/* font size 13px and line 18px weight 700*/
.emp-name-content {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.813rem;
    line-height: 1.125rem;
}

/* font size 12px and line 16px weight 400*/
.feedback-sm-text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
}

/* font size 12px and line 16px weight 700*/
.sm-text {
    font-family: 'UniNeue-Regular', Sans-serif !important;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
}

.detail-lable-head {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #1E293B;
}

/* 12 px light text */
.detail-label-light-text {
    font-family: 'UniNeue-Normal', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #475569;
}

/* font size 16px and line 22px weight 700*/
.document-innercard-content-title {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;
}

/* font size 13px and line 18px weight 400*/
.document-innercard-content-subtitle {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.813rem;
    line-height: 1.125rem;
}

/* button */
.common-button-text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-weight: 700;
    font-style: normal;
    font-size: 0.875rem;
    line-height: 1.188rem;
    padding: 0.625rem 1.875rem;
}

/* font size 18px and line 24px weight 700*/
.announcement-inner-title {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

/* font size 18px and line 24px weight 700*/
.basic-card-heading {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
}

/* font size 16px and line 24px weight 400*/
.announcement-inner-content {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1.5rem;
}

/* font size 14px and line 20px weight 400*/
.announcement-pin-content {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
}

.announcement-pin-content-bold {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.25rem;
}


/* For form labels */

.form-label {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #1E293B;
}

.tool-tip-font {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #ffff;
}


textarea:focus,
input:focus {
    outline: none !important;
}

.wrap-text-table {
    white-space: pre-line !important;
    width: 30rem !important;
}

.line-through {
    text-decoration-line: line-through !important;
}

.wrap-text-label {
    white-space: pre-line !important;
    width: 80% !important;
}

/* #####################################common texts#################################### */
.text14_bold {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem !important;
    line-height: 1.188rem !important;
}

.text14_light {
    font-family: 'UniNeue-Normal', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem !important;
    line-height: 1.188rem !important;
}

.text16_bold {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 1rem !important;
    line-height: 1.188rem !important;
}

.text16_medium {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem !important;
    line-height: 1.188rem !important;
}

.text16_light {
    font-family: 'UniNeue-Normal', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem !important;
    line-height: 1.188rem !important;
}

.text_ellipsis {
    white-space: nowrap !important;
    overflow: hidden !important;
    text-overflow: ellipsis !important;
}

.text17_bold {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.188rem;
}

.text17_light {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.188rem;
}

.text18 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.625rem;
    line-height: 0.875rem;
}

.text19 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

.text20 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

.text20-bold {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

.text21 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.313rem;
    line-height: 1.875rem;
}

.text22 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.875rem;
}

.text22-bold {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.375rem;
    line-height: 1.875rem;
}

.noteTextMsg {
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 400;
    color: #475569;
    font-size: 0.75rem;
}

.highlight-sm-Text {
    font-family: "UniNeue-Regular", Sans-serif;
    display: flex;
    font-weight: 700;
    font-size: 0.75rem;
    color: #3d1d76;
}

.noteTextBold {
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 800;
    color: #000000;
    font-size: 0.75rem;
}

.text26 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.875rem;
}

.text27 {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.625rem;
    line-height: 1.875rem;
}

.profile-text-bold {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 12px;
    color: #000000;
}

.card-content-subtitle {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.753rem;
}

.font-weight-700 {
    font-weight: 700 !important;
}

input[type=desable],
textarea {
    outline: none;
}

.text1_25rem-bold {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.25rem;
    line-height: 20px;
}

.table-header {
    font-weight: 900;
    color: #1e293b;
    font-family: "UniNeue-Regular", Sans-serif;
}

.suggestion-card-content {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 4rem;
}

.size_875rem{
    font-size: 0.875rem;
}