.suggestion-gridcontainer {
    display: grid;
    grid-template-columns: 1fr 2fr;
    grid-gap: 20px;
    height: 100%;
    grid-template-areas:
        "instruction status"
        "notice target";
    margin-top: 10px;
}

.instruction {
    grid-area: instruction;
}

.notice {
    grid-area: notice;
    height: 92%;
}

.status {
    grid-area: status;
}

.target {
    grid-area: target;
    height: 92%;
}

@media (max-width: 1200px) {
    .suggestion-gridcontainer {
        grid-template-columns: 1fr;
        grid-template-areas:
            "instruction"
            "status"
            "target"
            "notice";
    }

    .status,
    .target {
        width: 100%;
    }
}

.card-inner-sugg {
    display: flex;
    padding: 10px;
    border-radius: 12px;
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
    margin: 0px;
    /* padding: 0px; */
    overflow-y: hidden;
    overflow-x: hidden;
}

.instruction-content {
    max-height: 200px;
    overflow-y: auto;
}

.target-dashboard {
    height: 230px;
}

.flex-center {
    justify-content: center;
    align-items: center;
}

.suggestion-image-styles {
    display: flex;
    justify-content: center;
    align-items: center;
    max-height: 14.5rem;
}

.suggestion-screen-screen-scroll {
    overflow-y: scroll;
    height: 62vh;
    padding: 10px;
}

.thred-Fixed-suggestion {
    position: sticky;
    top: 0;
    z-index: 1;
}

.p-placeholder-evaluator {
    height: 40px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.serchIconSize-evaluator {
    z-index: 1;
    position: relative;
    right: 3rem;
    top: 1px;
}
.serchIconSize-master-screen {
    z-index: 1;
    position: absolute;
    right: 0.5rem;
    top: 4px;
}
.serchIconSize-evaluator-super-admin {
    z-index: 1;
    position: absolute;
    right: 2px;
}

.sendBack {
    background: rgba(61, 29, 118, 0.1);
}

.custom-pre {
    width: 11rem;
    text-wrap: wrap;
    max-height: 6rem;
}

.popup-overflow {
    text-wrap: wrap;
    /* max-height: 3rem; */
    /* overflow-y: scroll; */
}

.Sugg-horizontal-scroll {
    overflow-y: auto;
    white-space: nowrap;
    max-width: 230px;
}

.custom-pre-suggestor {
    width: 16rem;
    text-wrap: wrap;
    /* max-height: 6rem; */
}