.appcenterlogo {
    height: 2.875rem;
    width: 2.875rem;
    margin: 0.313rem;
    cursor: pointer;
}

.inner-app {
    height: 3.75rem;
    width: 3.75rem;
    background: #F8FAFC;
    border: 1px solid #F1F5F9;
    box-sizing: border-box;
    box-shadow: 2px 2px 4px rgba(203, 213, 225, 0.3), 4px 4px 10px rgba(174, 174, 192, 0.5);
    border-radius: 10px;

}

.app-center-card {
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);
    border-radius: 20px 20px 0px 0px;
    width: 100%;
}

.appcenter-text {
    margin-bottom: 14px;
}



@media (max-width: 700px) {
    .appcentercolumn {
        flex-basis: 0 !important;
        flex-grow: 1 !important;
        max-width: 100% !important;
    }
}