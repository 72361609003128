#reusable-admin-table.input[type="checkbox"] {
  visibility: visible !important;
  opacity: 1 !important;
}

#reusable-admin-table.thead th {
  vertical-align: baseline !important;
  padding: 0.75rem !important;
  width: 0% !important;
  /* min-width: 40px; */
}


#reusable-admin-table.table-container {
  max-height: 100px;
  overflow-y: auto;
}


#reusable-admin-table.table thead th:nth-child(-n+4) {
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: #E2E8F0;
  font-size: 14px !important;
}

#reusable-admin-table thead th {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #E2E8F0;
  font-size: 14px;
  white-space: nowrap;
}

#reusable-admin-table tbody td:nth-child(-n+4) {
  position: sticky;
  position: -webkit-sticky;
  top: 0;
  z-index: 2;
  background-color: #f8f9fa;
}

#reusable-admin-table th:nth-child(1),
#reusable-admin-table td:nth-child(1) {
  left: 0;
}

#reusable-admin-table th:nth-child(2),
#reusable-admin-table td:nth-child(2) {
  left: 15px;
}

#reusable-admin-table th:nth-child(3),
#reusable-admin-table td:nth-child(3) {
  left: 61px;
}

#reusable-admin-table th:nth-child(4),
#reusable-admin-table td:nth-child(4) {
  left: 172px;
}

.approve th:nth-child(2),
.approve td:nth-child(2) {
  left: 34px !important;
}

.approve th:nth-child(3),
.approve td:nth-child(3) {
  left: 76px !important;
}

.approve th:nth-child(4),
.approve td:nth-child(4) {
  left: 184px !important;
}


.approve th:nth-last-child(-n+3) {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #E2E8F0;
}

.approve td:nth-last-child(-n+3) {
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  background-color: #f8f9fa;
}

.approve th:nth-last-child(1),
.approve td:nth-last-child(1) {
  right: 0;
}

#reusable-admin-table.table thead {
  position: sticky;
  top: 0;
  z-index: 4;
  background-color: #E2E8F0;
}

.table-scroll-sticky {
  margin-top: 20px;
  overflow-x: auto;
  overflow-y: scroll;
  height: 50vh;
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  /* z-index: 1001 */
}

.editBtn {
  height: 30px;
  width: 90% !important;
  background-color: #FFFFFF;
  border: 1px solid #F2F4FB !important;
  border-radius: 12px;
  box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #64748B;
  padding-left: 10px;
}

.editDrp {
  height: 30px;
  width: 98px;
  background-color: #FFFFFF;
  border: 1px solid #F2F4FB !important;
  border-radius: 12px;
  box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #64748B;
  padding-left: 10px;
}


.input-error {
  border: 3px solid red !important;
}

.input-error-txt {
  color: red !important;
  font-size: smaller;
}

.error-txt {
  display: flex;
  flex-direction: column;
}