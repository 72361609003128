/* :root {
    --circlebackgroud: #4e42f3;
} */

/* The actual timeline performance (the vertical ruler) */
.timeline-performance {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

/* The actual timeline (the vertical ruler) */
.timeline-performance::after {
    content: '';
    position: absolute;
    width: 1.2px;
    background-color: #94A3B8;
    top: 0;
    bottom: 0;
    left: 7.4%;
    margin-left: -3px;
}

.circle-text-performance {
    position: absolute;
    left: 5.6px;
    width: 37px !important;
    height: 37px !important;
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    top: 3px;
    border-radius: 50%;
    font-size: 0.875rem;
    color: #ffffff;
    z-index: 2;
    padding: 8px 10px;
}

/* Container around content */
.container-performance {
    padding: 10px 25px;
    position: relative;
    background-color: inherit;
    width: 95%;
}

/* The circles on the timeline */
.container-performance::after {
    content: '';
    position: absolute;
    width: 36px;
    height: 36px;
    /* border: 1px solid #94a3b8; */
    top: 0px;
    left: 4.9px;
    border-radius: 50%;
    z-index: 1;
}

.container2-performance::after {
    content: '';
    position: absolute;
    width: 43px;
    height: 43px;
    left: 0.6%;
    top: 0px;
    background-color: white;
    border: 1px solid #94A3B8;
    box-sizing: border-box;
    border-radius: 50%;
    z-index: 1;
}

/* Place the container to the left */
.left {
    left: 0;
}

/* Place the container to the right */
.right {
    left: 9.2%;
}

.h-liness {
    position: absolute;
    height: 0px;
    width: 70px;
    left: 10px;
    top: 35px;
    border: 1px solid #94A3B8;
}

/* Fix the circle for containers on the right side */
.right::after {
    left: -54px;
}

/* The actual content */
.content {
    position: relative;
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
    right: 5%;
}

.edit-card-performance {
    height: 100% !important;
}

.performancetimeline-container {
    height: 20vh;
    overflow-y: auto;
    margin-top: 10px;
}

.mtop12px {
    margin-top: 12px !important;
    margin-bottom: 12px !important;
}

.performancetimeline-container ol {
    padding-left: 0px;
    padding-top: 10px;
}

.emp_inner {
    width: 2.375rem;
    height: 2.375rem;
    /* background: #3A83F8; */
    border: 1px solid #94a3b8;
    box-sizing: border-box;
    border-radius: 40px;
    outline: 1px solid #94a3b8;
    outline-offset: 3px;
}

.mt-5px {
    margin-top: 3px !important;
}

.emp_circle_text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #FFFFFF;
    padding: 8px;
}

.line {
    position: absolute;
    width: 70px;
    height: 0px;
    left: 50px;
    top: 15px;
    border: 1px solid #94A3B8;
    background-color: #94A3B8;
}

.performance-task-data {
    font-family: 'Uni Neue', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 11px;
    line-height: 12px;
    color: #1E293B;
}

.emp-text {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.181rem;
    color: #1E293B;
}

.emp-task {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.181rem;
    color: #1E293B;
    margin-left: 3px;
}

.emp-text-item {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem;
    line-height: 1.181rem;
    color: #1E293B;
    margin-left: 3px;
}

.emp-time {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: right;
    color: #64748B;
}

.dot {
    width: 4px;
    height: 4px;
    background: #94A3B8;
    border-radius: 50%;
    margin-right: 3px;
}

label {
    margin-bottom: 0rem !important;
}

.mt20per {
    margin-top: 0px !important;
}

@media (max-width: 1200px) {
    .mtop12px {
        margin-top: 0px !important;
    }

    .edit-card-performance {
        height: 100% !important;
    }

    .mt20per {
        margin-top: 6rem !important;
    }
}

@media (max-width: 768px) {
    .card-inner {
        margin-top: 10px !important;
        margin-left: 5px !important;
    }

    .mt20per {
        margin-top: 0px !important;
    }

}