.form-label-notification-bold {
    font-family: 'UniNeue-Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #1E293B;
}

.n-placeholder-text {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.pushNotification-container {
    max-height: 56vh;
    min-height: 30vh;
    overflow-y: auto;
}

.dropdown-Text {
    margin-right: 1.875rem;
    width: 7.5rem !important;
}

.viewImage {
    position: absolute;
    left: 0;
    margin-left: 2rem;
}

.group-width {
    width: 18% !important;
}

@media (max-width: 750px) {
    .group-width {
        width: 100% !important;
    }
}

@media (max-width: 450px) {
    .group-width {
        width: 100% !important;
    }
}

.scrollmenu {
    width: 98%;
    overflow-x: auto;
    padding: 0px 10px 5px 5px;
    margin: 0px 0px 0px 10px;
}

.sparshDescription {
    max-height: 20vh;
    overflow-y: auto;
}

.sparshPopupHeight {
    max-height: 70vh;
}

.auto-line-break {
    line-break: anywhere;
}

.date-input {
    height: 48px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}