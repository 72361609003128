.declaration-text{
    background-color: #4f55dd;
    color: #ffffff !important;
    font-weight: 100;
}

.span-text{
    font-weight: 700;
}

.declaration-image{
    width: 24rem;
}

.popup-close{
    position: absolute;
    color: white;
    opacity: 1;
    top: 0.25rem;
    right: 0.5rem;
}

.declaration_popup{
    width: auto !important;
}
