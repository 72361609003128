.paySpace-option-text {
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-left: 3.5%;
    font-size: 0.875rem;
    line-height: 20px;
    color: #475569;
}

.paySpace-text {
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 19px;
    color: #1E293B;
    ;
}

.filter-btn-choice-pay {
    height: 2.875rem;
    width: 5.875rem;
    background: linear-gradient(138.32deg,
            rgba(0, 0, 0, 0.5) 8.26%,
            rgba(255, 255, 255, 0.5) 91.02%),
        #f1f5f9;
    background-blend-mode: soft-light, normal;
    border: 1px solid rgba(255, 255, 255, 0.4);
    /* box-shadow: -2px -2px 8px #f2f4f8, 3px 3px 8px #e2e8f0; */
    border-radius: 12px;
}

.minMaxValues::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}


.minMaxValues:hover::before,
.minMaxValues:hover::after {
    --scale: 1;
}

.minMaxValues::after {
    content: '';
    /* border-top: 10px solid transparent;
    border-bottom: 0px solid transparent; */
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.minMaxValues::before,
.minMaxValues::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-left: 2%;
    width: 30% !important;
    /* height: 6%; */
    margin-top: -1%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
    background: whitesmoke;
}

.choicePayInfoIconStyle {
    display: flex;
    align-self: start;
    /* margin-top: 10px; */
}

.info_icon_flexi_pay {
    margin-left: 30%;
    margin-bottom: 10%;
    height: 20px;
    width: 20px;
}

.info_icon_paySpace {
    margin-left: 15%;
    margin-bottom: 10%;
    height: 20px;
    width: 20px;
}

.invalid-input {
    border: 1px solid #F2F4FB;
    border-radius: 8px;
    font-size: 0.875rem;
    font-style: normal;
    box-shadow: inset 2px 2px 4px #e59797, inset -1px -1px 2px #EDF2FF;
    padding: 7px;
    outline: none;
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 500;
    color: #1E293B;
    width: 100%;
    background-color: #FBF2F2;
}

.warnIcon {
    margin: auto;
    margin-left: -2rem;
}

.choice-pay-screen-scroll {
    overflow-y: scroll;
    height: 73vh;
    padding: 10px;
}

.table-row-width {
    width: 11rem;
}

.tool-tip-font-choicePay {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    color: #0f0f0f;
    background-color: #CBD5E1;
    border-radius: 20px;
    width: 28rem;
    z-index: 1002
}

.tool-tip-font-warn {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.45rem;
    color: #0f0f0f;
    box-shadow: inset 2px 2px 4px #EFC2C2, inset -1px -1px 2px #EDF2FF;
    background-color: #FBF2F2;
    border: 1px solid #F2F4FB;
    border-radius: 20px;
    width: 16rem;
    padding: 5px;
    z-index: 1002
}

.max-h-37vh {
    max-height: 37vh;
}

.max-h-45vh {
    max-height: 45vh;
}

.max-h-52vh {
    max-height: 52vh;
}

.amount-justify-content-end {
    justify-content: end;
    margin-right: 3rem;
}

.parameter-align-item-right {
    text-align: right;
    margin-right: 3rem;
}

.choice-pay-sector {
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9;
    background-blend-mode: soft-light, normal;
    border: 2px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(51, 65, 85, 0.26);
    border-radius: 20px;
    border-color: #3D1D76;
}

.text-align-right {
    text-align: right;
}

.td-choice-pay {
    font-size: 0.85rem;
    color: #717171;
}

.toggle-card-heading-choice-pay {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;
    color: #3D1D76;
}

.choice-pay-thead thead {
    position: sticky;
    top: 0px;
    margin: 0 0 0 0;
    z-index: 1001
}

.table-container {
    max-height: 60vh;
    overflow-y: auto;
}

.fileDownloadIcon {
    height: 25px;
    width: 25px;
}

.highlited-text {
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    color: #3d1d76;
}

.right-arrow-gif-icon {
    cursor: pointer;
    margin-right: 0.5rem;
    width: 2rem;
    height: 2rem;
}

.rightGIF {
    cursor: pointer;
    margin-right: 0.5rem;
    width: 1rem;
    height: 1rem;
    transform: rotate(90deg); /* Rotate 90 degrees to the right */
}

.tooltip-disc-padding {
    padding-left: 15px;
    padding-right: 15px;
}

/*-----Download COMP Statement Tooltip Styles----- */
.COMPDownloadIcon::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}

.COMPDownloadIcon:hover::before,
.COMPDownloadIcon:hover::after {
    --scale: 1;
}

.COMPDownloadIcon::after {
    content: '';
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.COMPDownloadIcon::before,
.COMPDownloadIcon::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-left: -10%;
    /* width: 8% !important; */
    margin-top: -3.2%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

.DownloadIconStyle {
    display: flex;
    align-self: start;
    /* margin-top: 10px; */
}