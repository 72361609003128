.LA-card {
  /* height: 102%; */
  /* height: 99%; */
  height: 83vh;
  width: 100%;
  background: linear-gradient(250.32deg, #e9eaee 13.13%, #e4e6eb 86.79%),
    #ebecf0;
  background-blend-mode: soft-light, normal;
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5),
    4px 4px 8px rgba(51, 65, 85, 0.26);
  border-radius: 20px;
  overflow-y: auto;
}
.LA-card-right {
  /* height: 102%; */
  /* height: 99%; */
  height: 83vh;
  width: 100%;
  background: linear-gradient(250.32deg, #e9eaee 13.13%, #e4e6eb 86.79%),
    #ebecf0;
  background-blend-mode: soft-light, normal;
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5),
    4px 4px 8px rgba(51, 65, 85, 0.26);
  border-radius: 20px;
  /* overflow-y: auto; */
}

.la-wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: minmax(150px, auto);
  width: 100%;
  /* height: 95%; */
  height: 86%;
  grid-template-areas:
    "main child"
    "main child"
    "main child"
    "main child"
    "main child";

  grid-gap: 15px;
}

.la-main {
  grid-area: main;
}

.la-child {
  grid-area: child;
}

.la-left-wrapper-grid {
  display: grid;
  grid-template-columns: 0.4fr 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: minmax(150px, auto);
  width: 100%;
  height: 10rem;
  grid-template-areas:
    "image details"
    "image details"
    "image details"
    "image details"
    "image details";
  grid-gap: 24px;
}

.la-image {
  grid-area: image;
}

.la-details {
  grid-area: details;
}

.white-card-subcard-outer {
  width: 100%;
  background: #ffffff;
  box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0;
  border-radius: 10px 10px 10px 10px;
  /* height: 144%; */
}

.white-card-subcard {
  width: 100%;
  background: #ffffff;
  box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0;
  border-radius: 10px 10px 10px 10px;
}
.selected-card-subcard {
  width: 100%;
  background: #ffffff;
  /* background: linear-gradient(250.32deg, rgba(239, 240, 243, 0.6) 13.13%, rgba(234, 235, 239, 0.6) 86.79%), linear-gradient(181.58deg, #3D1F78 1.5%, #2A72B6 98.83%); */
  /* box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0; */
  box-shadow: 0px 0px 0px #3D1F78, 0px 1px 8px #2A72B6;
  border-radius: 10px 10px 10px 10px;
}
.transparent-card-subcard {
  width: 100%;
  height: 80vh;
  /* height: -webkit-fill-available; */
  /* background: #ffffff; */
  /* box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0;
  border-radius: 10px 10px 10px 10px; */
}
.inner-Card {
  margin-top: 0px !important;
  margin-bottom: 10px !important;
  margin-left: 10px !important;
  margin-right: 10px !important;
  height:-webkit-fill-available;
}
.card-height {
  height: 100%;
  overflow-y: auto;
}
.learningBox {
  background: #FFFFFF;
  border: 1px solid #CBD5E1;
  box-sizing: border-box;
  border-radius: 8px;
  width: 95%;
  padding: 12px;
}

.noDataAtCenter {
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
}

.la-left-toggle-wrapper-grid {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 1fr 1fr 1fr;
  grid-auto-rows: minmax(150px, auto);
  width: 100%;
  height: 3.6rem;
  grid-template-areas:
    "toggle toggle toggle"
    "toggle toggle toggle"
    "toggle toggle toggle";
  grid-gap: 24px;
}

.la-toggle {
  grid-area: toggle;
}

.la-left-item-wrapper-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
  grid-auto-rows: minmax(150px, auto);
  width: 100%;
  /* height: 34.5vh;  */
  grid-template-areas:
    "item"
    "item"
    "item"
    "item"
    "item";
  grid-gap: 24px;
}

.la-item {
  grid-area: item;
}

/* ***************************************************************************** */
@media (max-width: 1280px) {
  .la-left-toggle-wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    height: 4rem;
    grid-template-areas:
      "toggle toggle toggle"
      "toggle toggle toggle"
      "toggle toggle toggle";
    grid-gap: 24px;
  }
}

@media (max-width: 900px) {
  .la-left-toggle-wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    height: 4.4rem;
    grid-template-areas:
      "toggle toggle toggle"
      "toggle toggle toggle"
      "toggle toggle toggle";
    grid-gap: 24px;
  }
  .profile-card-text-bold {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 9px;
    line-height: 12px;
    color: #000000;
  }

  .profile-text-bold {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 14px;
    line-height: 12px;
    color: #000000;
  }

  .profile-card-text-light {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 12px;
    color: #000000;
  }
}

@media (max-width: 700px) {
  .la-left-toggle-wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    height: 4.6rem;
    grid-template-areas:
      "toggle toggle toggle"
      "toggle toggle toggle"
      "toggle toggle toggle";
    grid-gap: 24px;
  }
}

@media (max-width: 600px) {
  .la-left-toggle-wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    height: 4.9rem;
    grid-template-areas:
      "toggle toggle toggle"
      "toggle toggle toggle"
      "toggle toggle toggle";
    grid-gap: 24px;
  }
}

@media (max-width: 400px) {
  .la-left-toggle-wrapper-grid {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    height: 5.7rem;
    grid-template-areas:
      "toggle toggle toggle"
      "toggle toggle toggle"
      "toggle toggle toggle";
    grid-gap: 24px;
  }

  .header-profile-icon {
    cursor: pointer;
    width: 6.153rem;
    height: 6.234rem;
    border-radius: 50%;
  }

  .profile-card-text-bold {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 9px;
    line-height: 12px;
    color: #000000;
  }

  .profile-card-text-light {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 9px;
    line-height: 12px;
    color: #000000;
  }
}

@media (max-width: 728px) {
  .la-wrapper-grid {
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    /* height: 186%; */
    grid-template-areas:
      "main"
      "main"
      "main"
      "main"
      "main"
      "main"
      "main"
      "child"
      "child"
      "child"
      "child"
      "child"
      "child"
      "child";
    grid-gap: 24px;
  }
  .LA-card {
    /* height: 102%; */
    width: 100%;
    background: linear-gradient(250.32deg, #e9eaee 13.13%, #e4e6eb 86.79%),
      #ebecf0;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #ffffff;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5),
      4px 4px 8px rgba(51, 65, 85, 0.26);
    border-radius: 20px;
    overflow-y: auto;
  }
}
.dataListhight {
  height: 90% !important;
  overflow-y: auto;
  margin: 10px 0px 10px 0px;
  padding: 10px 0px 10px 0px;
}
.radio-wh {
  height: 20px;
  width: 20px;
}

.tataAcademy{
  height: 5em;
  width: 5em;
}
/* .tataAcademy-onheader{
  height: 4em;
  width: 4em;
} */
.marginLeftlA{
  margin-right: 10px;
}
.h-15vh {
  height: 15vh !important;
}

.dropdown-style {
  margin: 10px;
}

.textbox-la {
  height: 7vh;
  width: 100%;
  background-color: #F2F4FB;
  border: 1px solid #F2F4FB !important;
  border-radius: 12px;
  box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #1E293B;
  padding: 10px;
}
.textbox-outer{
  margin-left: 10px;
  margin-right: 10px;
}

.right-bottom{
  text-align: end; 
  position: absolute;
  bottom: 2%;
  right: 50%;
}
.margin-top-25px{
  margin-top: -25px;
}
.margin-left-10px{
  margin-left: 10px;
}
.height-internaldevelopmentCard {
  height: 25vh;
  /* overflow-y: auto; */
  padding: 8px;
}
.welcomepopuptitle{
  font-family: 'UniNeue-Regular', Sans-serif !important;
    font-style: normal;
    font-weight: 700 !important;
    font-size: 1.125rem !important;
}
.welcomomepopupdescription{
  margin: 15px;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: #000000;
}