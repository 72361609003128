.modal_outer_box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 1px 1px black;
}

.popup-box {
  position: fixed;
  background: #00000050;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  box-shadow: 1px 1px 1px 1px black;
}

.large-modal {
  width: 55vw !important;
  /* Occupy the 90% of the screen width */
}

.top_position {
  position: absolute !important;
  top: 5%;
}

.modal_inner_box {
  position: relative;
  margin: 0 auto;
  /* border-radius: 4px; */
  padding: 0 20px;
  overflow: auto;
  /* box-shadow: 1px 1px 1px 1px black; */
  background: #FFFFFF;
  box-shadow: -2px -2px 12px rgba(30, 41, 59, 0.2), 4px 4px 12px rgba(30, 41, 59, 0.2);
  border-radius: 10px;

}

.modal_title {
  font-family: 'UniNeue-Regular', Sans-serif !important;
  font-weight: normal !important;
  font-size: 18px !important;
}

.modal-title {
  font-family: 'UniNeue-Regular', Sans-serif !important;
  font-weight: normal !important;
  font-size: 18px !important;
}

.popup_text {
  font-family: 'UniNeue-Regular', Sans-serif !important;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem !important;
  line-height: 1.25rem;
  color: #1E293B !important;
}

.popup_sm-text {
  font-family: 'UniNeue-Regular', Sans-serif !important;
  font-style: normal;
  font-weight: 600;
  font-size: 0.75rem !important;
  line-height: 1.25rem;
  color: #1E293B !important;
}

.popup_scroller {
  overflow-y: auto !important;
  overflow-x: hidden;
  min-height: 50vh !important;
  max-height: 50vh !important;
}
.popup_TVscroller {
  overflow-y: auto !important;
  overflow-x: hidden;
  min-height: 40vh !important;
  max-height: 40vh !important;
}

.popup_text-light {
  font-family: 'UniNeue-Regular', Sans-serif !important;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem !important;
  line-height: 1.25rem;
  color: #1E293B !important;
}

.apply_leave_modal {
  border-radius: 10px !important;
  padding: 5px;
  overflow-x: hidden !important;
  width: 415px;
  background-color: #FFF !important;
}

@media only screen and (max-width: 800px) {
  .large-modal {
    width: 100vw !important;
  }

  .top_position {
    display: none;
  }
}