.mainView {
    display: flex;
    flex-direction: row;
    /* height: 100vh; */
    background-color: #F0F0F3;
}

.childOne {
    display: block;
    width: 4.0rem;
}

.childTwo {
    display: flex;
    flex-direction: column;
    /* width: 100%; */
    /* height: 100%; */
    /* height: 110vh; */
}

.childTwoInnerOne {
    height: 5.0rem !important;
}

.childTwoInnerTwo {
    margin: 0;
    padding: 0;
    width: 101.5%;
    /* height: 100vh; */
    /* overflow-y: auto; */
    /* overflow-x: hidden; */
    /* height: 89%; */
    /* height: 98vh; */
    /* height: -webkit-fill-available; */
}

@media only screen and (max-height: 625px) {
    .childTwoInnerTwo {
        height: 88vh !important;
        overflow-y: auto;
    }
}

/*----Mobile----*/
@media only screen and (max-width: 600px) {
    .childOne {
        display: none;
    }
}
.main-content {
    position: absolute;
    top: 0px;
    left: 60px;
    width: 95%;
}

@media only screen and (max-width: 600px) {
    .main-content {
        position: absolute;
        top: 0px;
        left: 0px;
        width: 100%;
    }
}