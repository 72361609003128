.document_card {
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(51, 65, 85, 0.26);
    border-radius: 20px;
}

.document_data_card {
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    border-radius: 0.625rem;
    margin-top: 1em;
    height: 6.5rem;
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
}

.hide_icon {
    width: 1.25rem;
    height: 1.25rem;
    box-sizing: border-box;
}
.info-icon{
    width: 1rem;
    height: 1rem;
}

.bottom-button {
    position: absolute;
    bottom: 0.875rem;
    right: 1.875rem
}

.input-div {
    background: #FFFFFF;
    /* border: 2px solid #E2E8F0; */
    border-radius: 8px;
}

.from_Input {
    border: 2px solid #E2E8F0;
    outline: none;
    border-radius: 8px;
}

.from_Input:active {
    border: none;
    outline: none;
}
.pt-20px{
    padding-top: 20px !important;
}

.mt-23px {
    margin-top: 23px !important;
}

.mt-17px {
    margin-top: 17px !important;
}

.button-outlet {
    border: 1px solid #1E293B;
    box-sizing: border-box;
    border-radius: 12px;
}


input[type="checkbox"]:checked {
    background: none !important;
    background-color: transparent !important;
}

.toast-div-main {
    padding: 16px 56px;
    position: absolute; 
    z-index: 2;
    height: 54px;
    width:32rem;
    left: 30%;
    top: 10rem;
    background: #5C8C36;
    box-shadow: 0px 0px 16px rgba(92, 140, 54, 0.5);
    border-radius: 12px;
}

.toast-message-div {
    position: static;
    left: 11.34%;
    right: 11.34%;
    top: 29.63%;
    bottom: 29.63%;
    font-family: 'UniNeue-Normal', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.375rem;    
    text-align: center;
    color: #F8FAFF;
    }
.note-text-description {
    font-family: 'UniNeue-Regular', Sans-serif !important;
    font-style: normal !important;
    font-weight: 400 !important;
    font-size: 0.89rem !important;
    line-height: 1rem !important;
}