.tab-small-labels {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: #475569;
}

.main-contain-transfer {
    height: 67.5vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.scroller-transfer {
    overflow-y: auto;
    height: 600px;
}

.modal-headers {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 1rem 1rem;
    border-top-left-radius: calc(.3rem - 1px);
    border-top-right-radius: calc(.3rem - 1px);
}

.status-dashboard {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: #475569;
}

.tab-active1 {
    color: #3D1D76;
    height: 60px;
    /* width: 240px !important; */
    background: rgba(61, 29, 118, 0.1);
    border-radius: 12px 12px 0px 0px;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: -2px 8.5px;
    text-align: center;
    border-bottom: 3px solid #3D1D76;
}

.tab-inactive1 {
    cursor: pointer;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    /* width: 155px !important; */
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #64748B;
    flex: none;
    flex-grow: 0;
    margin: 0px 16px;
    text-align: center;
}

.main-contain-child-1 {
    overflow-y: auto;
    overflow-x: hidden;
}