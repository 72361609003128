.filter-section{
    gap: 0.5rem;
    flex-wrap: wrap;
}

.btn{
    border: none;
}

.radio-button{
    flex-wrap: wrap;
}