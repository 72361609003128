.tata-logo {
    width: 3rem;
    height: 1.969rem;
    margin-top: 1.875rem;
    margin-bottom: 2.063rem;
}

.tabLabel::before,
.tabLabel::after {
    --scale: 0;
    position: absolute;
    margin-left: 90%;
    margin-top: 40%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

.tabLabel::before {
    content: attr(data-tooltip);
    /* max-width: fit-content; */
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;

    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}

.tabLabel:hover::before,
.tabLabel:hover::after {
    --scale: 1;
}

.tabLabel::after {
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
    margin-top: 55%;
}

.element-style {
    color: red !important;
}

.navicon {
    width: 3rem;
    height: 3rem;
    margin-top: 20px;
    padding: 10px;
    background: #6269CC;
    border-radius: 15px;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: -2.5px -2.5px 12px 1px rgba(145, 128, 235, 0.59), 2.5px 2.5px 5px #41386F;
}

.navicon:hover {
    box-shadow: inset -2.5px -2.5px 5px rgba(150, 128, 235, 0.59), inset 2.5px 2.5px 5px #A6ABBD;
}

.side-body {
    position: fixed;
    width: 4.0rem;
    background: linear-gradient(250.32deg, rgba(239, 240, 243, 0.6) 13.13%, rgba(234, 235, 239, 0.6) 86.79%), linear-gradient(181.58deg, #3D1F78 1.5%, #2A72B6 98.83%);
    background-blend-mode: soft-light, normal;
    height: 100%;
    z-index: 1000;
    display: block;
}

.navicon-active {
    width: 3rem;
    height: 3rem;
    margin-top: 20px;
    padding: 10px;
    background: linear-gradient(317.7deg, rgba(0, 0, 0, 0.4) 0%, rgba(255, 255, 255, 0.4) 105.18%), #E7EBF0;
    background-blend-mode: soft-light, normal;
    border-radius: 15px;
    box-shadow: inset -2.5px -2.5px 5px #FAFBFF, inset 2.5px 2.5px 5px #A6ABBD;
}

.log-out-icon {
    height: 3.688rem;
    cursor: pointer;
}

.bm-menu-wrap {
    width: 80px !important;
}

/* .bm-overlay {
   width: 100px !important;
} */

/* .react-burger-menu-btn {
    width: 100px !important;
} */

/* .bm-burger-button {
    position: absolute;
    width: 80px !important;
    height: 10% !important;
    width: 1.2rem;
    height: 1.2rem;
    left: 16px;
    top: 18px;
} */

/* **********88888888888888888888888888****************************8 */
.bm-burger-button {
    position: absolute;
    width: 1.2rem;
    height: 1.2rem;
    left: 16px;
    top: 18px;
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
    background: #373a47;
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
    height: 24px;
    width: 24px;
}

/* Color/shape of close button cross */
.bm-cross {
    background: #bdc3c7;
}

.sidebardesktophide {
    visibility: visible;
    display: block;
}

.mobileSidebar {
    visibility: hidden;
    display: none;
}

/* *****************************************************************888 */

@media only screen and (max-width: 600px) {
    .sidebardesktophide {
        visibility: hidden;
        display: none;
    }

    .mobileSidebar {
        visibility: visible;
        display: block;
        position: fixed;
        width: 20.0rem;
        background: linear-gradient(250.32deg, rgba(239, 240, 243, 0.6) 13.13%, rgba(234, 235, 239, 0.6) 86.79%), linear-gradient(181.58deg, #3D1F78 1.5%, #2A72B6 98.83%);
        background-blend-mode: soft-light, normal;
        height: 100%;
        z-index: 1000;
        top: 0;
        margin-left: 30;
        border-top-right-radius: 8px;
        border-bottom-right-radius: 8px;
    }

    .navicon {
        margin-top: 0px;
        padding: 10px;
        border-radius: 15px;
    }

    .navicon-active {
        margin-top: 0px;
        padding: 10px;
        border-radius: 15px;
    }

    .hrLineNavBar {
        border: 1px solid rgba(255, 255, 255, 0.2);
    }

    .crossIcon {
        color: #FFFFFF;
    }
}

@media only screen and (max-width: 400px) {

    .navicon {
        border-radius: 15px;
        font-size: 15px !important;
    }

    .navicon-active {
        border-radius: 15px;
        font-size: 15px !important;
    }
}

.sideBarHeight {
    overflow-y: auto;
    height: 85vh;
}