
.filterIcon{
    height: 27px;
    cursor: pointer;
}

.serchIconSize{
    z-index: 1;
    position: relative;
    right: 4rem;
    top: 7px;
}
