* {
    padding: 0;
    font-size: 16px;
    font-family: "UniNeue-Regular", Sans-serif;
}

.success-msg {
    background: #e1face;
    border-radius: 8px;
}

.ant-progress-circle .ant-progress-text {
    font-size: 0.7em !important;
}

.warning-msg {
    background: #fff8e1;
    border-radius: 8px;
}

.warning-icon {
    height: 29.67px;
    width: 27.07px;
}

.success-icon {
    height: 29.67px;
    width: 27.07px;
}

.warning-vertical-bar {
    height: 31.58px;
    width: 4px;
    background: #e8b607;
    border-radius: 0px 8px 8px 0px;
}

.success-vertical-bar {
    height: 31.58px;
    width: 4px;
    background: #5c8c36;
    border-radius: 0px 8px 8px 0px;
}

.box {
    box-shadow: none !important;
    width: 100%;
    background: none;
    max-height: 100vh;
}

.mainBox {
    margin-top: -19px;
    overflow: hidden;
}

.marginTop {
    margin-top: inherit;
}

.boxOne {
    margin-bottom: 1.438rem;
}

.leaveQuotesCard {
    background-color: rgba(255, 255, 255, 1);
}

.boxUpcomingLeaves {
    height: 31.5vh;
    /* background-color: rgba(255, 255, 255, 1); */
}

.LeaveSummaryText {
    color: #1e293b;
}

.applyLeaveBtn {
    background: linear-gradient(90deg, #3d1d76 0%, #653daf 100%);
    border-radius: 12px;
    color: #ffffff;
    font-family: "Unineue-Regular", Sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    width: 110%;
    border: none;
}

.applyLeaveBtn:hover {
    text-decoration: none;
    color: #fff;
}

.lineImg {
    height: 140px;
    margin-top: 8px;
}

.leavesBtn {
    font-family: "UniNeue-regular", Sans-serif;
    background: #f2f4fb;
    box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
    border-radius: 12px;
    padding: 10px;
    cursor: pointer;
}

.upcomingLeaves {
    color: #1e293b;
}

.holidayBtnColor {
    color: #3d1d76;
    font-weight: 700;
    font-size: 0.875rem;
}

table thead tr {
    background: #e2e8f0;
    border: 1px;
    border-radius: 6px !important;
}

table th {
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 900;
    color: #1e293b;
}

table tr td {
    font-family: "UniNeue-Regular", Sans-serif;
}

.tble {
    height: 60%;
    overflow-y: scroll;
}

.tble tr:nth-child(even) {
    background: #f1f5f9;
}

.holidayThead {
    font-size: 0.75rem;
}

/* LeaveHistory Table */
.leaveHistoryCard {
    background-color: rgba(255, 255, 255, 1);
}

.leaveHistoryText {
    font-size: 1.125rem;
    font-weight: 400;
    line-height: 1.5rem;
    color: #1e293b;
    font-style: normal;
}

.leaveHistoryTable {
    height: 250px !important;
    overflow-y: scroll;
    color: #475569;
}

.thead {
    font-size: 0.875rem;
    text-overflow: ellipsis;
}

.btnColor {
    color: #1e293b;
    font-weight: 700;
}

.td {
    font-size: 0.75rem;
    color: #717171;
}

.tdLeaveHistory {
    font-size: 0.875rem;
    color: #717171;
}

.withdrawImg {
    height: 1.875rem;
    cursor: pointer;
}

/* Leave Category Colors */
.colorCategory {
    min-height: 20px;
    min-width: 2px;
}

/* Apply Leave Card */
.applyLeaveCard {
    padding: 15px;
    overflow-x: hidden;
}

.apply_leave_modal {
    border-radius: 10px !important;
    padding: 5px;
    overflow-x: hidden !important;
    width: 415px;
    background-color: #fff !important;
}

.date {
    font-family: "UniNeue-Regular", Sans-serif;
    color: #1e293b;
    font-style: normal;
    font-weight: 800;
    font-size: 0.875rem;
}

.closeIcon {
    height: 15px;
    width: 15px;
    cursor: pointer;
}

.firstRow {
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
}

.leaveType {
    border-radius: 4px;
    text-align: center;
    font-family: "UniNeue-Regular", Sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.875rem;
    width: 5rem;
    height: 1.625rem;
    padding: 2px 8px;
}

.preveLeaveType {
    border-radius: 4px;
    text-align: center;
    font-family: "UniNeue-Regular", Sans-serif;
    text-transform: uppercase;
    font-weight: normal;
    font-size: 0.875rem;
    /* width: 5rem; */
    cursor: pointer;
    height: 1.625rem;
    padding: 2px 8px;
}

.datePlaceholder {
    color: #94a3b8;
    font-size: 1rem;
    font-weight: 700;
    font-style: normal;
}

.applyLeavecardBox {
    padding-inline: inherit;
}

.textarea {
    border: 1px solid #e2e8f0;
    border-radius: 8px;
    margin-left: auto;
    width: 98%;
}

.radio {
    height: 18px;
    width: 18px;
}

.radioTxt {
    font-size: 0.875rem;
    margin: 0 5px;
}

.totalDaysCard {
    background: #f1f5f9;
    padding: 15px;
    border-radius: 8px;
}

.totalDaycardtxtLabel {
    font-family: "UniNeue-Regular", Sans-serif;
    color: #475569;
    font-size: 0.8rem;
}

.magin {
    margin-left: 3.75rem;
}

.totaldaysdate {
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 600;
    width: 100px;
    color: #1e293b;
    font-size: 0.8rem;
}

.notetxt {
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 600;
    color: #475569;
    font-size: 0.75rem;
}

.flex-column-job {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.approverTxt {
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 700;
    font-size: 0.875rem;
}

.approverName {
    font-family: "UniNeue-Light", Sans-serif;
    font-size: 0.875rem;
    font-weight: 700;
}

.addAnotherButtonPos {
    display: flex;
    justify-content: flex-end;
}

.addAnotherBtn {
    color: #3d1d76;
    font-size: 0.8rem;
    font-weight: 700;
}

.btnBox {
    box-shadow: 0px -3px 7px rgb(30 41 59 / 16%);
}

.dateInput {
    width: 130%;
}

.dateImg {
    height: 1.25rem;
    width: 1.25rem;
    top: 0.875rem;
    bottom: 0.875rem;
    margin-left: auto;
}

.marginDate {
    margin-left: 1.25rem;
}

.messageCard {
    height: 65px;
    width: 102%;
}

.marginLeaveLR {
    margin-left: 16rem;
    margin-right: 16rem;
}

.msg {
    color: #5c8c36;
    font-family: "UniNeue-Regular", Sans-serif;
    font-size: 0.875rem;
    width: fit-content;
}

.warningMsg {
    color: #e8b607;
    font-size: 0.875rem;
    font-family: "UniNeue-Regular", Sans-serif;
}

.validateBtn:hover {
    opacity: 0.9;
    cursor: pointer;
}

/* **************************  slider **************************************** */
.slick-list {
    width: 650px !important;
}

.slick-slider {
    position: unset !important;
}

.slick-next {
    margin-right: -3%;
    margin-top: -8px;
    height: 40px;
}

.slick-prev {
    height: 40px;
    left: 2px;
    margin-top: -10px;
}

.slick-slide {
    padding-left: 65px;
}

/* When less then two */
.twoCircle {
    text-align: center;
}

.leaveQuotaTxt {
    margin-left: 0px !important;
}

/* Leave quota Progress Bar */
.ant-progress-circle .ant-progress-inner {
    box-shadow: 0px 0px 8px rgba(100, 116, 139, 0.5) !important;
}

.ant-progress {
    margin-top: 5px !important;
    margin-top: -7px !important;
    margin-left: -6px !important;
}

.vertical-line {
    border: 1px solid #cbd5e1;
    transform: rotate(90deg);
}

.leaveQuotaName {
    color: #475569;
    font-size: 0.875rem;
    font-weight: 700;
    line-height: 1.188rem;
    text-align: center;
    margin-top: 5px;
    margin-left: 0px !important;
}

.ant-progress-circle,
.ant-progress-text {
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1.125rem;
    line-height: 1.5rem;
    color: #1e293b;
}

.slick-slide {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

.slick-initialized {
    text-align: center !important;
}

.leave-quota-space {
    margin-left: 2.5rem;
    margin-right: 3rem;
}

.pre-next-icon {
    height: 60px;
    width: 20px;
}

.marRight {
    margin-right: -18px !important;
}

.marLeft {
    margin-left: -20px !important;
}

.width56 {
    width: 56%;
}

.width95 {
    width: 90%;
    margin-top: 0.7rem;
}

.width100 {
    width: 100%;
}

.height100 {
    height: 100%;
}

.marginleft {
    margin-left: 0.9rem !important;
}

.marginleft1 {
    margin-left: 0.8rem !important;
}

/* Mobile View */
@media screen and (max-width: 600px) {
    .date {
        font-size: 1.3rem !important;
    }

    .twoCircle {
        margin-left: -400px !important;
    }

    .leaveQuotaTxt {
        margin-left: -400px !important;
    }

    .marginTop {
        margin-top: 10px !important;
    }

    .leaveQuotaName {
        color: #475569;
        font-size: 0.875rem;
        font-weight: 700;
        line-height: 1.188rem;
        text-align: center;
        margin-top: 5px;
        margin-left: 0px !important;
    }

    .leaveQuotesCard {
        margin-bottom: inherit !important;
    }

    .tble {
        overflow-y: scroll !important;
    }

    .leaveHistoryTable {
        overflow-y: scroll;
    }

    /* Apply leave card */
    .apply_leave_modal {
        margin-left: 7px !important;
        max-width: 90% !important;
    }

    .approverTxt {
        font-size: 1.305rem !important;
    }

    .approverName {
        font-size: 1.305rem !important;
    }

    .radioTxt {
        font-size: 1.305rem
    }

    .leaveType {
        font-size: 1.305rem !important;
        width: 6.5rem;
        height: 2.5rem;
    }

    .dateImg {
        height: 2rem !important;
        width: 2rem !important;
    }

    .form-label {
        font-size: 1.5rem !important;
    }

    .msg,
    .notetxt,
    .totaldaysdate,
    .totalDaycardtxtLabel {
        font-size: 1.3rem !important;
    }

    .mr-5 {
        margin-right: 4rem !important;
    }
}

.slick-slide {
    padding-top: 10px !important;
}

@media screen and (max-width: 450px) {
    .apply_leave_modal {
        max-width: 80% !important;
    }
}

@media screen and (max-width: 769px) {
    .leave-grid-screen {
        display: grid !important;
        grid-template-columns: 1fr !important;
        grid-template-rows: 1fr 1fr 1fr !important;
        /* grid-auto-rows: minmax(150px, auto); */
        width: 100%;
        height: 82vh;
        grid-template-areas:
            "quota"
            "holiday"
            "history"!important;
        grid-gap: 24px;
    }
}

@media screen and (max-width: 1030px) {
    .slick-slide {
        padding-left: 0px !important;
        margin-left: -74px !important;
    }

    .slick-next {
        right: 150px !important;
    }

    .leave-quota-space {
        margin-left: 0.5rem;
        margin-right: 0rem;
    }

    .leave-quota-circle {
        gap: 10px;
    }
}

@media screen and (max-width: 900px) {
    /* .slick-slide {
        padding-left: 0px !important;
        margin-left: -85px !important;
    }

    .slick-next {
        right: 190px !important;
    } */
}

@media screen and (max-width: 1300px) {
    .slick-list {
        width: 560px !important;
    }

    .slick-next {
        right: -35px !important;
    }
}

@media screen and (max-width: 1400px) {
    .slick-list {
        width: 586px !important;
        margin-left: 24px !important;
    }

    .slick-next {
        right: 22px !important;
    }
}

.leave-grid-screen {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    /* grid-auto-rows: minmax(150px, auto); */
    width: 100%;
    /* height: 82vh; */
    grid-template-areas:
        "quota quota holiday holiday"
        "history history history history";
    grid-gap: 24px;
}

.leave-quota {
    grid-area: quota;
}

.leave-history {
    grid-area: history;
}

.upcoming-holiday {
    grid-area: holiday;
}