#reusable-table.input[type="checkbox"] {
  visibility: visible !important;
  opacity: 1 !important;
}

#reusable-table.thead th {
  vertical-align: baseline !important;
  padding: 0.75rem !important;
  width: 0% !important;
  /* min-width: 40px; */
}

#reusable-table.table-container {
  max-height: 100px;
  overflow-y: auto;
}


#reusable-table.table thead th:nth-child(-n+4) {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #E2E8F0;
}

#reusable-table.table tbody td:nth-child(-n+4) {
  position: sticky;
  position: -webkit-sticky;
  /* For Safari */
  z-index: 2;
  background-color: #f8f9fa;
}

/* Position each sticky column */
#reusable-table.table th:nth-child(1),
#reusable-table.table td:nth-child(1) {
  left: 0;
}

#reusable-table.table th:nth-child(2),
#reusable-table.table td:nth-child(2) {
  /* left: 34px; */
  left: 21px;
}

#reusable-table.table th:nth-child(3),
#reusable-table.table td:nth-child(3) {
  /* left: 72px; */
  left: 63px;
}

.pending th:nth-child(2),
.pending td:nth-child(2) {
  /* left: 34px; */
  left: 24px !important
}

.pending th:nth-child(3),
.pending td:nth-child(3) {
  /* left: 72px; */
  left: 63px !important
}

#reusable-table.table th:nth-child(4),
#reusable-table.table td:nth-child(4) {
  left: 162px;
}

.special-sticky th:nth-last-child(-n+3) {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #E2E8F0;
}

.special-sticky td:nth-last-child(-n+3) {
  position: sticky;
  position: -webkit-sticky;
  z-index: 2;
  background-color: #f8f9fa;
}

.special-sticky th:nth-last-child(1),
.special-sticky td:nth-last-child(1) {
  right: 5px;
}

.special-sticky th:nth-last-child(2),
.special-sticky td:nth-last-child(2) {
  right: 56px;
}

.special-sticky th:nth-last-child(3),
.special-sticky td:nth-last-child(3) {
  right: 175px;
}

.conditional th:nth-last-child(2),
.conditional td:nth-last-child(2) {
  right: 74px !important;
}

.conditional th:nth-last-child(3),
.conditional td:nth-last-child(3) {
  right: 171px !important;
}

.width th:nth-last-child(2),
.width td:nth-last-child(2) {
  right: 76px !important;
}

.width th:nth-last-child(3),
.width td:nth-last-child(3) {
  right: 173px !important;
}

#reusable-table.table thead {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: #E2E8F0;
}

.editBtn {
  height: 30px;
  width: 80%;
  background-color: #FFFFFF;
  border: 1px solid #F2F4FB !important;
  border-radius: 12px;
  box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #64748B;
  padding-left: 10px;
}

.editDrp {
  height: 30px;
  width: 98px;
  background-color: #FFFFFF;
  border: 1px solid #F2F4FB !important;
  border-radius: 12px;
  box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #64748B;
  padding-left: 10px;
}

.editTime {
  height: 30px;
  width: 38px;
  background-color: #FFFFFF;
  border: 1px solid #F2F4FB !important;
  border-radius: 12px;
  box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #64748B;
  padding-left: 8px;
}

.addBtn {
  color: #FFF;
  text-align: center;
  border-radius: 10px;
  border: 1px solid #94A3B8;
  padding: 5px 20px;
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  font-weight: 400;
  float: right;
  font-size: 0.875rem;
  font-style: normal;
}

.input-error {
  border: 3px solid red !important;
}

.input-error-txt {
  color: red !important;
  font-size: smaller;
}

.error-txt {
  display: flex;
  flex-direction: column;
}

.table-scroll-sticky {
  margin-top: 20px;
  overflow-x: auto;
  overflow-y: scroll;
  height: 50vh;
  position: sticky;
  top: 0px;
  margin: 0 0 0 0;
  /* z-index: 1001 */
}

#Hrs .common-dropdown-text {
  background-color: #F2F4FB;
  border: 1px solid #F2F4FB;
  border-radius: 10px;
  font-size: 0.875rem;
  font-style: normal;
  width: 100%;
  box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
  padding: 3px !important;
  outline: none;
  font-family: "UniNeue-Regular";
  font-weight: 800;
  color: #1E293B;
}

/* Ensure the dropdown menu appears above other elements */

.table-row .dropdown-menu {
  z-index: 1050 !important;
  position: absolute !important;
  transform: translateY(0) !important;
}

.table-row .dropdown-menu.show-above {
  bottom: 100% !important;
  top: auto !important;
  transform: translateY(-0.5rem) !important;
  /* Adjust spacing as needed */
}