.notification-container {
    position: absolute;
    /* overflow-y: auto; */
    overflow-x: hidden;
    right: 4.5%;
    z-index: 1050;
    top: 74px;
    width: 24.75rem;
    /* height: 29rem; */
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #FFFF;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #F1F5F9;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);
    border-radius: 0.75rem;
}

.notification-title {
    margin: 0.625rem;
    padding: 0.6rem;
}

.notification-data {
    padding-top: 0.313rem;
    padding-left: 0.8rem;
}

.notification-circle-shape {
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: border-box;
    border-radius: 40px;
    text-align: center;
    margin-right: 0.625rem;
    padding: 3.5px;
}

.notification-emp_circle_text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
    line-height: 2.188rem;
    color: #FFFF;
}

.notificationCardHeight {
  height: 80vh !important;
}

.notification-scroller {
    overflow-y: auto;
    height: 69vh;
}

.landing-notification {
    height: 47vh;
    overflow-y: auto;
}

.landing-notification-withoutData {
    height: auto;
}

.notification-Icons{
    height: 20px;
    width: 20px;
}

.notification-iconsMTM {
    height: 19px;
    width: 28px;
    margin-top: 3px;
}

@media (max-width: 1050px) {
    .notification-container {
        top: 70px;
    }
}

@media (max-width: 700px) {
    .notification-container {
        top: 65px;
    }

}

/* ////////////////---Mobile----//////////////// */
@media only screen and (max-width: 600px) {
    .notification-container {
        top: 60px;
    }
}

@media only screen and (max-width: 400px) {
    .notification-container {
        top: 50px;
    }
}