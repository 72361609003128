.profile-container {
    position: absolute;
    right: 1.5%;
    z-index: 1050;
    top: 74px;
    width: 17.75rem;
    height: 29rem;
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #FFFF;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #F1F5F9;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);
    border-radius: 0.75rem;
}

.profile-container-first {
    width: 17.75rem;
    height: 11.438rem;
    background: #F1F5F9;
    box-sizing: border-box;
    border-radius: 0.75rem 0.75rem 0px 0px;
}

.admin-profile-container {
    position: absolute;
    right: 1.5%;
    z-index: 1050;
    top: 74px;
    height: 15rem;
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #FFFF;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #F1F5F9;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);
    border-radius: 0.75rem;
}

.scroller-profile {
    overflow-y: auto;
    height: 65vh;
}

.profile-image {
    width: 5.605rem;
    height: 3.737rem;
}

.header-profile-icon {
    cursor: pointer;
    width: 8.153rem;
    height: 8.234rem;
    border-radius: 50%;
}

.profile-card-text-bold {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 200;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.profile-card-text-light {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 18px;
    color: #000000;
}

.profile-card-scroller {
    overflow-y: auto;
    overflow-x: hidden;
    /* min-height: '70%'; */
    height: 20rem !important;
}

.profile-heading {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.detail-lable-head {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #1E293B;
}

.detail-lable-desc {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #1E293B;
}

.profile-detail-lable-detail {
    font-family: 'UniNeue-Normal', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.75rem;
    line-height: 1.125rem;
    color: #1E293B;
}

.profile-list-image {
    width: 1rem;
    height: 1rem;
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
    margin-right: 1.25rem;
    cursor: pointer;
}

.profile-list-item-text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1.013rem;
    color: #475569;
}

.profile-list-item-text-vw {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1vw;
    color: #475569;
}

/* .profile-list-item:hover{

.profile-list-item:hover {
    background: rgba(20, 103, 179, 0.1);
    box-sizing: border-box;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
} */
.hr-line {
    align-items: center;
    height: 0px;
    border: 1px solid #E2E8F0;
}

/*----profile style----*/
.card-height {
    height: 500px;
}

.label {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
}

.label:active {
    color: #3D1D76;
    height: 45px;
    width: 168px;
    background: rgba(61, 29, 118, 0.1);
    border-radius: 12px 12px 0px 0px;
}

.bottom-line {
    position: absolute;
    left: 3%;
    right: 3%;
    top: 12%;
    bottom: 100%;
    border: 1px solid #CBD5E1;
}
/* 
.tab-active {
    color: #3D1D76;
    height: 45px;
    width: 168px !important;
    background: rgba(61, 29, 118, 0.1);
    border-radius: 12px 12px 0px 0px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    text-align: center;
    border-bottom: 3px solid #3D1D76;
    padding: 10px;
} */
/* 
.tab-inactive {
    cursor: pointer;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    width: 155px !important;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #64748B;
    flex: none;
    flex-grow: 0;
    margin: 0px 16px;
    text-align: center;
    padding: 10px;
} */

/* .autocomplete-div{

} */

/*------profile tab------*/
.p-heading {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;
    color: #1E293B;
}

.p-sub-heading {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: #475569;
}

.span-character-css{
    position: absolute !important;
    bottom: -1.25rem;
    font-size: 0.625em;
}

.p-sub-heading-new {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 10px;
    color: #787571;
}

.p-sub-note {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 20px;
    color: #475569;
}

.profileCardScroller {
    overflow-y: scroll;
    height: 39vh;
}

.toggle-card-heading {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 22px;
    color: #1E293B;
}

.p-placeholder-text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.p-placeholder-error {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
    box-shadow: inset 2px 2px 4px #EFC2C2, inset -1px -1px 2px #EDF2FF;

    height: 8vh;
    width: 100%;
    background-color: #FBF2F2;
    border: 1px solid #F2F4FB;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #EFC2C2, inset -1px -1px 2px #EDF2FF;
    outline: none;
}

.p-placeholder {
    height: 48px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}
.p-placeholder-sug {
    height: 40px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.p-placeholder-admin {
    height: 41px !important;
}

/* autocomplete input */
/* .LXilg {
    height: 48px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    background: #e9ecef !important;
    list-style: none !important;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
} */

.edit-img {
    height: 24px;
    width: 24px;
    border-radius: 0px;
}

.cancl-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    width: 111px;
    height: 40px;
    border: 1px solid #1E293B;
    box-sizing: border-box;
    border-radius: 12px;
}

.save-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    width: 95px;
    height: 40px;
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    border-radius: 12px;
}

.cancel-txt {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #1E293B;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
}

.save-txt {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
}

.hz-lines {
    border: 1px solid #CBD5E1;
}

.togglr-on {
    margin-top: 16%;
    height: 22px;
    width: 46px;
    border-radius: 0px;
}

.address-txt {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: #64748B;
}

.bank-addoption-text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 19px;
    color: #3D1D76;

    /* position: absolute; */
    /* right: 0; */
}

.b-placeholder {
    height: 48px;
    width: 355px;
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
}

.hidden {
    display: 'none' !important
}

.declaration-box {
    position: relative;
    /* width: 35em; */
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
    padding: 10 10px 10px !important;
    overflow: auto;
}

.popupCard-box {
    position: relative;
    /* width: 35em; */
    margin: 0 auto;
    background: #fff;
    border-radius: 12px;
    padding: 10 10px 10px !important;
    overflow: auto;
}

.body-box {
    position: relative;
    /* width: 35em; */
    margin: 0 auto;
    background: #fff;
    border-radius: 4px;
    padding: 0 10px 10px;
    overflow: auto;
}

.declaration-yes-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    width: 95px;
    height: 40px;
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    border-radius: 12px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 140%;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    margin: 0px 10px;
}

.declaration-no-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    width: 95px;
    height: 40px;
    border: 1px solid #1E293B;
    box-sizing: border-box;
    border-radius: 12px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 140%;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #1E293B;
    margin: 0px 10px;
}

datalist {
    position: absolute;
    background-color: white !important;
    border: 1px solid blue !important;
    border-radius: 0 0 5px 5px;
    border-top: none;
    font-family: sans-serif;
    width: 350px;
    padding: 5px;
    max-height: 10rem;
    overflow-y: auto
}

.option {
    background-color: rgb(230, 11, 11) !important;
    padding: 4px;
    color: blue !important;
    margin-bottom: 1px;
    font-size: 18px;
    cursor: pointer;
}

.profile-inner-card {
    /* overflow-y: auto; */
    /* height: 70vh; */
    padding-bottom: 5px;
    margin-bottom: 7px;
    /* min-height: 50vh; */
    /* max-height: 67vh; */
}

.dropdownText {
    background-color: 'pink' !important;
}

.unstyled {
    list-style-type: none !important;
}

.MuiAutocomplete-popup-popupIndicator {
    background-color: 'red' !important;
}

.info_icon_dependent {
    height: 20px;
    width: 20px;
}

.declaration-border-0px {
    border-width: 0px !important;
}

@media (max-width: 1280px) {
    .profileCardScroller {
        height: 33vh;
    }
}

@media (min-width: 1024px) {
    .profileCardScroller {
        height: 36vh;
    }
}

@media (max-width: 900px) {
    .profileCardScroller {
        height: 30vh;
    }
}


@media (max-width: 600px) {
    .profileCardScroller {
        height: 28vh;
    }
}

@media (max-width: 700px) {
    .profile-container {
        top: 60px;
    }
}

@media (max-width: 400px) {
    .profile-container {
        top: 50px;
    }
}