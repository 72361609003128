/* Leave Withdraw Popup */
.closeBtn {
    cursor: pointer;
    height: 15px;
    width: 15px;
}

.withdrawLeaveBtnNo,
.withdrawLeaveBtnYes {
    color: #1E293B;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #94A3B8;
    background-color: #FFF;
    font-weight: 600;
}

.tata-policy-link {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 19px;
    color: #3D1D76;
}

.Link-text {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 19px;
    color: black;
}

.withdrawLeaveBtnYes {
    background: #3D1D76;
    color: #FFF;
}

.withdrawLeaveBtnYes:hover {
    background: #3D1D76;
    color: #FFF;
}

.modalWidth {
    width: 70%;
}