:root {
    --casualWidth: 0%;
    --availedWidth: 0%;
    --privilegeLeaveWidth: 0%;
    --sickLeaveWidth: 0%;
}

.vertical-fill-card {
    height: 85vh !important;
    width: 100%;
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);
    border-radius: 24px;
}

.plannedLeaveImg {
    margin-left: 5.5rem;
    margin-top: 5rem;
}

.plannedLeaveScroller {
    display: flex;
    align-items: center;
    justify-content: center;
}

.manager-circle-text {
    position: absolute;
    left: 0.5px;
    width: 38px !important;
    height: 38px !important;
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    top: 0px;
    border-radius: 50%;
    font-size: 0.875rem;
    color: #ffffff;
    z-index: 2;
    padding: 8px 10px;
}

.round-circle {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
    line-height: 2.188rem;
    text-align: center;
    color: #FFFFFF;
    background-color: #1E293B;
}

.child-team-card {
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
    margin: 8px;
}

.Nodata-img {
    height: 10vw;
    /* width: 20px ; */
}

.profile-circle-shape {
    width: 4rem;
    height: 4rem;
    box-sizing: border-box;
    border-radius: 40px;
    text-align: center;
}

.direct-reportee-circle-shape {
    width: 2.5rem;
    height: 2.5rem;
    box-sizing: border-box;
    border-radius: 40px;
    text-align: center;
}

.emp_circle_text_directreportees {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    text-align: center;
    line-height: 2.188rem;
    text-align: center;
    color: #FFFFFF;
}

.cal-img {
    width: 100px !important;
}

.blue-sm-circle {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    box-sizing: border-box;
    align-self: center;
    background-color: #3D1D76;
}

.mt-minus-one {
    margin-top: -1rem !important;
}

.subheight-child {
    /* height: 150px;
    width: 250px; */
    padding-left: 10px;
    margin-left: 5px;
    justify-self: center;
    align-items: center;
    overflow-y: auto;
    overflow-x: auto;
    /* position: relative; */
    top: 20px;
}

.teams-sm-dot {
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background-color: #3D1D76;
    margin-top: 1rem;
}

.summary-badge-count {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 1.875rem;
    line-height: 2.563rem;
    text-align: center;
    color: #000000;
}

.summary-heading {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.375rem;
    line-height: 1.875rem;
    color: #1E293B;
}

.summary-badge-label {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: #000000;
}

.summary-golden-badge-img {
    width: 3.198rem;
    height: 3.231rem;
}

.scroller-coi {
    overflow-y: auto;
    height: 45vh;
    /* height: 285px; */
}

.summary-btn-active {
    background: #F2F4FB;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    border-radius: 12px;
    width: 5.688rem;
    height: 2.688rem;

    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.summary-btn-inactive {
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #EBECF0;
    background-blend-mode: soft-light, normal;
    border: 1px solid #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 8px rgba(203, 213, 225, 0.5);
    border-radius: 10px;

    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.summary-silver-badge-img {
    width: 2.383rem;
    height: 2.407rem;
}

.summary-leave-org-color {
    background: linear-gradient(90deg, #F0D07C -41.18%, rgba(240, 208, 124, 0) 144.12%);
    /* width: 3rem; */
    height: 21px;
    border-radius: 5px;
}

.casual-leave {
    width: var(--casualWidth) !important;
}

.availed-leave {
    width: var(--availedWidth) !important;
}

.privilege-leave {
    width: var(--privilegeLeaveWidth) !important;
}

.sick-leave {
    width: var(--sickLeaveWidth) !important;
}

.summary-leave-green-color {
    background: linear-gradient(90deg, #63A07C 0%, rgba(99, 160, 124, 0) 94.14%);
    width: 13rem;
    height: 21px;
    border-radius: 5px;
}

.summary-leave-orange-color {
    background: linear-gradient(90deg, #EDA460 0%, rgba(237, 164, 96, 0) 96.78%);
    width: 12rem;
    height: 21px;
    border-radius: 5px;
}

.summary-leave-blue-color {
    background: linear-gradient(270deg, #7BA1EB 0%, rgba(123, 161, 235, 0) 104.76%);
    transform: matrix(-1, 0, 0, 1, 0, 0);
    width: 9rem;
    height: 21px;
    border-radius: 5px;
}

.summary-org-box {
    width: 1.25rem;
    height: 1.25rem;
    background: #F0D07C;
    border-radius: 2px;

    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: #FFFFFF;
}

.summary-green-box {
    width: 1.25rem;
    height: 1.25rem;
    background: #63A07C;
    border-radius: 2px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: #FFFFFF;
}

.summary-orange-box {
    width: 1.25rem;
    height: 1.25rem;
    background: #EDA360;
    border-radius: 2px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: #FFFFFF;
}

.summary-blue-box {
    width: 1.25rem;
    height: 1.25rem;
    background: #7BA1EB;
    border-radius: 2px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 0.75rem;
    line-height: 1rem;
    text-align: center;
    color: #FFFFFF;
}

.details-heading {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 800;
    font-size: 1rem;
    line-height: 1.188rem;
    color: #3D1D76;
}

.details-sub-heading-bold {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.details-sub-heading-light {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.manager-team-grid {
    display: grid !important;
    grid-template-columns: 1fr 1fr 0.7fr 0.7fr 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-auto-rows: minmax(150px, auto);
    width: 100%;
    height: 85vh !important;
    grid-template-areas:
        "team-one team-one team-two team-two team-three team-three team-three"
        "team-one team-one team-four team-four team-four team-four team-four";
    grid-gap: 24px;
}

.team-direct-reportee {
    grid-area: team-one;
}

.team-department-reportee {
    grid-area: team-two;
}

.team-planned-leave {
    grid-area: team-two;
}

.team-leadership {
    grid-area: team-three;
}

.team-approval {
    grid-area: team-four;
}

@media (max-width: 1200px) {
    .manager-team-grid {
        grid-template-columns: 1.5fr 1fr 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        width: 100%;
        height: 85vh;
        grid-template-areas:
            "team-one team-two team-two team-three team-three team-three"
            "team-one team-four team-four team-four team-four team-four";
        ;
    }
}

@media (max-width: 768px) {
    .manager-team-grid {
        grid-template-columns: 1fr 1fr !important;
        grid-template-rows: repeat(4, 1fr) !important;
        width: 100%;
        height: 85vh;
        grid-template-areas:
            "team-one team-one"
            "team-two team-two"
            "team-three team-three"
            "team-four team-four";
    }

    .c-w {
        width: 50%;
    }
}

@media (max-width: 425px) {
    .leadership-mobile-screen {
        flex-wrap: wrap;
    }

    .img-leadership {
        margin-left: auto;
        margin-right: auto;
    }
}

.reporteedata {
    height: 200px;
    overflow-y: scroll;
}

.countTxt {
    font-size: 0.8em;
    margin-top: 8px;
    font-family: 'UniNeue-Regular', Sans-serif;
}

.countValuefirst {
    margin-left: 25px;
    font-size: 0.8rem;
    padding: 3px;
    color: #FFF;
    background-color: #9B72E5;
    border: 1px solid #9B72E5;
}

.countValue {
    margin-left: 45px;
    padding: 3px;
    font-size: 0.8rem;
    color: #fff;
}

.charT {
    height: 10px;
}

.btnBox {
    box-shadow: 0px -3px 7px rgb(30 41 59 / 16%);
}

.popUpHeight {
    height: 58vh;
    /* height: 66vh; */
    overflow-y: auto;
    padding-right: 5px;
}

.note-comment {
    border: 2px solid #E2E8F0 !important;
    border-color: #E2E8F0 !important;
    padding: 5px !important;
    /* resize: none; */
}

.note-comment-inner {
    width: 100%;
    background: #ffffff;
    box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0;
    border-radius: 10px !important;
}

.note-comment-textarea {
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 19px;
    color: #64748b;
}

.tool-tip-teams {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    /* font-weight: 700; */
    font-size: 0.675rem;
    color: #6D3DC5;
    padding-left: 5px;
    padding-top: 2px;
    padding-bottom: 2px;
    padding-right: 2px;
    width: 10rem;
    background-color: #FFFFFF;
    border-radius: 12px;
    z-index: 1002
}

.vertical-line-style{
    background-color: #CBD5E1;
    height: 8rem;
    width: 2px;
    margin-left: 2rem;
    margin-top: 1rem;
}

.circle-bg-gradient {
    background: linear-gradient(225deg, #4B83D9 15.43%, #6881D7 46.89%, #8C7ED4 85.71%);
    border-radius: 50%;
    width: 96px;
    height: 95px;
    border: 4px solid #ffffff;
  }

.leave-quota-outer-card {
    margin-top: 1.875rem;
    padding-bottom: 1.25rem;
}

.team-overview-style {
    margin-left: 1.25rem !important;
    margin-right: 1.25rem !important;
}

.leadership-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.card-width {
    width: 617px !important;
}

.horizontal-scroll {
    overflow-x: auto;
    overflow-y: hidden;
}

.img-leadership {
    height: 180px;
    width: 180px;
}

.test {
    align-items: center;
    justify-content: center;
}

ul li::before {
    content: "" !important;
    /* color: red; */
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -0.9em;
}

.alice-carousel__stage-item {
    text-align: center !important;
}

.alice-carousel__prev-btn {
    display: none !important;
}

.alice-carousel__next-btn {
    display: none !important;
}

.alice-carousel__dots-item:not(.__custom):not(:last-child) {
    margin-right: 10px !important;
}

.alice-carousel {
    margin: 2px !important;
}

.alice-carousel__dots {
    text-align: center;
    position: inherit !important;
    bottom: 0px;
    left: 0%;
    right: 0%;
}

.table-header {
    padding: 0.5rem !important;
}

.table-data-font {
    font-size: 0.79rem !important;
}

.main-scroll-table {
    height: 33vh;
    overflow-y: auto;
}

.toggleStyle {
    display: flex;
    justify-content: center;
    /* padding: 10px; */
}

.toggleStyle-s {
    display: flex;
    /* justify-content: center; */
    /* padding: 10px; */
}

.team-deactivate-button-left {
    width: 45%;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(174, 174, 192, 0.5);
    border-radius: 12px 0px 0px 12px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #64748B;
    cursor: pointer;
    height: 36px;
}

.team-deactivate-button-right {
    width: 45%;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(174, 174, 192, 0.5);
    border-radius: 0px 12px 12px 0px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #64748B;
    cursor: pointer;
    height: 36px;
}

.team-activate-button-left {
    width: 45%;
    background: #EDF1FF;
    box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5), inset 2px 2px 4px #C2CDEF;
    border-radius: 12px 0px 0px 12px;
    height: 36px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #3D1D76;
    cursor: pointer;
}

.team-activate-button-right {
    width: 45%;
    background: #EDF1FF;
    box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5), inset 2px 2px 4px #C2CDEF;
    border-radius: 0px 12px 12px 0px;
    height: 36px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #3D1D76;
    cursor: pointer;
}

.reporteeScroller {
    overflow-y: hidden !important;
    height: 240px !important;
}