/*-----Admin Gems Exports Tooltip Styles----- */
.exportApplicationLabel::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}

.exportApplicationLabel:hover::before,
.exportApplicationLabel:hover::after {
    --scale: 1;
}

.exportApplicationLabel::after {
    content: '';
    /* border-top: 10px solid transparent;
    border-bottom: 0px solid transparent; */
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.exportApplicationLabel::before,
.exportApplicationLabel::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-left: -6%;
    /* width: 1% !important; */
    margin-top: -3.2%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}

.applicationIconStyle {
    display: flex;
    align-self: start;
    margin-top: 10px;
}

.gemsTable {
    position: relative; 
    min-height: 340px; 
    max-height: 340px; 
    overflow-x: auto; 
    white-space: nowrap; 
}