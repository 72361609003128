/* Top Row */
.overviewBtn {
    color: #3D1D76;
}

.newTask {
    height: 2.5rem;
    cursor: pointer;
}

.heightTodo {
    height: 65vh !important;
}

.heightTTodoDashboard {
    height: 76vh !important;
}

.scrollFilter {
    overflow-y: scroll;
}

.hrLine {
    margin-top: 0.8rem !important;
    width: '100%';
}

.vertical-separator {
    border: 1px solid #CBD5E1;
    transform: rotate(90deg);
    width: 18px;
    height: 0px;
    /* margin-right: 0.4rem;
    margin-left: 0.4rem; */
}

.popup-next-screen-txt-light {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #1E293B;
}

.popup-next-screen-txt-bold {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    color: #1E293B;
}

/* Cards */
.toDoMainCard {
    margin-top: 1rem;
    width: 66.125rem;
    height: 30.375rem;
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #EBECF0;
    overflow-y: auto;
}

.filtercard {
    margin-top: 1rem;
    /* margin-left: 2.625rem; */
    height: 30.375rem;
    width: 14.999rem;
}

.todo-inner-card {
    overflow-y: auto;
    height: 70vh;
    padding-bottom: 5px;
    margin-bottom: 7px;
    min-height: 50vh;
    max-height: 67vh;
}

/* Toggle CSS */
.activeTab {
    color: #3D1D76;
    height: 45px;
    width: 168px;
    background: rgba(61, 29, 118, 0.1);
    border-radius: 12px 12px 0px 0px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 20px;
    border-bottom: 2px solid #3D1D76 !important;
}

.inactiveTab {
    cursor: pointer;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #64748B;
    flex: none;
    flex-grow: 0;
    margin: 0px 25px;
}


/* Filter Card */

.filterTypetxt {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.80rem;
    line-height: 1.188rem;
    color: #475569;
}

.todoCards {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    border-radius: 8px;
    box-sizing: border-box;
}

.todoDatetxt {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.80rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.todoLabeltxt {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.80rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.todoSubLabelText {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.80rem;
    line-height: 20px;
    color: #64748B;
}

.filterBox {
    margin-top: 1rem;
    /* padding: 1px; */
    margin-left: 10px;
    height: 63vh !important;
}

.todo-scroll {
    overflow-y: auto;
    height: 75vh;
    margin-right: 20px;
}

/* ToDo Modal */

.toDoModal {
    font-family: "UniNeue-regular", Sans-serif;
    width: 28.5rem;
}

.toDoModalMedium {
    font-family: "UniNeue-regular", Sans-serif;
    width: 50rem;
    height: 55rem;
}

.todoModalBody {
    padding: 15px 15px 0px !important;
    padding-top: 0 !important;
    /* width: 28.5rem !important; */
}

input[type=file]::file-selector-button {
    border: none;
    padding: 3px 30px;
    float: right;
    border-radius: 12px;
    color: white;
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    cursor: pointer;
}

/* Personal data */
.dateIndicator {
    text-transform: uppercase;
}

.toDoListBox {
    width: 100%;
    padding: 20px;
}

.toDoBox {
    background: #FFFFFF;
    border: 1px solid #CBD5E1;
    box-sizing: border-box;
    border-radius: 8px;
    width: 100%;
    padding: 8px
}

.subtitle {
    color: #64748B;
}

.dueDate {
    color: #475569;
}

.linkTxt {
    text-decoration-line: underline;
    color: #1E293B;
}

/* Custom radio btn */

.labell {
    display: block;
    position: relative;
    padding-left: 20px;
    font-size: 15px;
}

.labell input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
}

/* custom radio */
.checkk {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #fff;
    border: 2px solid black;
    border-radius: 50%;
}

.checkk:after {
    content: "";
    position: absolute;
    display: none;
}

.labell input:checked~.checkk:after {
    display: block;
}

.labell .checkk:after {
    left: 5px;
    bottom: 3px;
    width: 5px;
    height: 10px;
    border: 1px solid #3D1D76;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

/* Custom Checkbox */
.checkBoxData {
    font-weight: 400;
    font-size: 0.875rem;
    font-family: 'UniNeue-Regular', Sans-serif;
    color: #1E293B !important;
    position: relative;
    padding-left: 25px;
    cursor: pointer;
}

.centr-div {
    display: flex;
    justify-content: center;
}

.center-child {
    margin-top: 5%;
}

.hidecheckBox {
    visibility: hidden;
}


.markk {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f8f9fa;
    border: 1px solid #d7dee8;
}

.checkboxMarkSize {
    position: absolute;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    background-color: #f8f9fa;
    border: 1px solid #d7dee8;
}

.boxx:hover input+.markk {
    background-color: #1c87c9;
}


.checkBoxData input:checked+.markk {
    background-color: #3d1d76;
}


.markk:after {
    content: "";
    position: absolute;
    display: none;
}


.checkBoxData input:checked+.markk:after {
    display: block;
}

.checkBoxData .markk:after {
    left: 6px;
    bottom: 4px;
    width: 6px;
    height: 11px;
    border: solid #eee;
    border-width: 0px 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

hr {
    margin-top: 0.2rem !important;
    margin-bottom: 0.1rem !important;
}

.popupWidth {
    width: 30% !important;
}

@media (max-width: 778px) {
    .popupWidth {
        width: 50% !important;
    }

}

@media (max-width: 576px) {
    .popupWidth {
        width: 80% !important;
    }

}