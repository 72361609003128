.Announcementimg {
    border-radius: 8px;
    border: none;
    padding: 0px;
}

.img-thumbnail {
    height: 7.5rem;
    width: 9.466rem;
}

.announcement-inside {
    list-style: none;
}

.announcement-title {
    font-family: 'UniNeue-Bold';
    font-style: normal;
    font-weight: 300;
    font-size: 0.938rem;
    line-height: 1.375rem;
    color: #282828;
    width: 80%;
    /* height: 22px; */
    margin-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    position: relative;
}

.main-contain ol {
    padding: 0px;
    margin-left: 10px;
    margin-right: 10px;
}

.main-contain-child {
    height: 87%;
    overflow-y: auto;
    overflow-x: hidden;
}

.main-contain-child-scroller {
    height: 54vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}
.main-contain-child-1 {
    overflow-y: auto;
    overflow-x: hidden;
}



.content-text {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #64748B;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    height: 38px;
    position: relative;
}

.content-area {
    height: 45px;
    resize: none;
}

.announcement-date {
    margin-right: 10px;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 500;
    font-size: 0.75rem;
    line-height: 0.75rem;
    text-align: right;
    color: #64748B;
}

.emoji-landing {
    height: 1.25rem;
    width: 1.25rem;
    margin: -0.188rem;
}

.reaction-text {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    text-decoration-line: underline;

    color: #3D1D76;
}

.outer-emoji {
    cursor: pointer;
}

.hover-emoji {
    z-index: 1000;
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 60px;
    position: absolute;
    top: 30px;
    height: 45px;
    padding-left: 10px;
    padding-right: 10px;
}

.emoji-outer {
    background: linear-gradient(138.32deg, rgba(0, 0, 0, 0.5) 8.26%, rgba(255, 255, 255, 0.5) 91.02%), #EAECF0;
    background-blend-mode: soft-light, normal;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: -2px -2px 8px #F2F4F8, 3px 3px 8px rgba(174, 174, 192, 0.4);
    border-radius: 23.4899px;
    height: 1.875rem;
    width: 1.875rem;
    cursor: pointer;
}

.emoji-outer:hover {
    background: linear-gradient(138.32deg, rgba(0, 0, 0, 0.5) 8.26%, rgba(255, 255, 255, 0.5) 91.02%), #EAECF0;
    background-blend-mode: soft-light, normal;
    border: 1px solid rgba(255, 255, 255, 0.4);
    box-shadow: -2px -2px 8px #F2F4F8, 3px 3px 8px rgba(174, 174, 192, 0.4);
    border-radius: 23.4899px;
    height: 3rem;
    width: 3rem;
    cursor: pointer;
    transition: 0.2s;
}
/* .content-text1 {
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.25rem;
    color: #64748B;
} */

@media (max-width: 700px) {
    .edit-card {
        margin-top: 10px;
    }

    /* .img-thumbnail {
        width: auto !important;
        height: auto !important;
    } */
    .img-thumbnail {
        height: 9.5rem !important;
        width: 10.466rem !important;
    }

    .announcementcontentcolumn {
        width: 100% !important;
        margin-top: 10px !important;
    }

    .content-text {
        width: 100% !important;
        /* height: 32px !important; */
    }

    .outer-emoji {
        margin-bottom: 20px !important;
    }

    .announcement-title {
        margin-bottom: 5px !important;
    }
}

@media (max-width: 576px) {
    .announcementcontentcolumn {
        /* width: 100% !important; */
        margin-left: 0px !important;
        /* margin-top: 10px !important; */
    }

    .announcement-title {
        margin-bottom: 5px !important;
    }
}