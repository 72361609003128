.sec-col {
    background: #3D1D76;
}

/* .column-1 {
    background-color: #F8FAFC;
    height: 100vh;
    border-top-right-radius: 50px;
    padding-top: 5vh;
} */

.column-2 {
    padding-top: 3vh;
    width: 60vh !important;
    height: 100vh;
    background: radial-gradient(48.2% 50% at 45.84% 50%, #6F43BD 0%, #3D1D76 100%);
    padding-left: 2rem;
}

@media screen and (max-width: 1300px) {
    body {
        overflow-y: auto;
    }

    .column-1 {
        width: 100%;
        border-radius: 0%;
        height: auto;
    }

    .column-2 {
        width: 100% !important;
    }
}
.height-css{
	height: auto;
    min-height: 80vh;
}

@media (min-width: 1800px) {
	.height-css{
		height: 75vh;
	}
}
@media screen and (min-width: 1400px) {
    .bottom-css{
        bottom : 90px !important
    }
}
@media screen and (max-width: 1300px) {
    .bottom-css{
        bottom : 0px !important
    }
    body{
        overflow-y: auto !important;
    }
}

.round-border {
    border-top-right-radius: 50px;
}


.main-box {
    width: 50%;
}

.login-head {
    font-family: 'UniNeue-bold', Sans-serif;
    font-weight: bold;
    font-style: normal;
    font-size: 2.2rem;
    color: #3D1D76;
    line-height: 2rem;
}

.login-subhead {
    font-family: 'UniNeue-Normal', Sans-serif;
    font-weight: 600;
    font-style: normal;
    font-size: 2.2rem;
    line-height: 4rem;
    color: #3D1D76;
}
.login-form{
    margin-top: 1.5rem;
}

.login-subpara {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-weight: 500;
    font-size: 1.05rem;
    color: #64748B;
    width:100%
}
.login-form-label{
    font-family: 'UniNeue-Regular', Sans-serif;
    font-weight: 500;
    font-size: 0.9rem;
    color: #475569;
}

.lst-item {
    border-bottom: none;
}

.inpt {
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB;
    border-radius: 8px;
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    padding: 7px;
    outline: none;
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 800;
    color: #1E293B;
}

.invalid {
    border: 1px solid #F2F4FB;
    border-radius: 8px;
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    box-shadow: inset 2px 2px 4px #EFC2C2, inset -1px -1px 2px #EDF2FF;
    padding: 7px;
    outline: none;
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 500;
    color: #1E293B;
    background-color: #FBF2F2;
}

.PassWarning {
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 400;
    font-size: 0.75rem;
    color: #FF5353;
}

.showPassBtn {
    padding: 10px;
    margin-left: -70px;
    font-family: "UniNeue-Regular", Sans-serif;
    text-transform: uppercase;
    color: #3D1D76;
    font-weight: 700;
    font-size: 0.625rem;
    cursor: pointer;
}

.showDisabledPassBtn {
    padding: 10px;
    margin-left: -70px;
    font-family: "UniNeue-Regular", Sans-serif;
    text-transform: uppercase;
    color: #64748B;
    font-weight: 700;
    font-size: 0.625rem;
    cursor: pointer;
}
.resetPassText{
    font-family: "UniNeue-Regular", Sans-serif;
    color: #542E92;
    font-weight: 700;
    font-size: 0.825rem;
    cursor: pointer;
}


.rememberMe {
    color: #666666;
    font-family: "UniNeue-Normal", Sans-serif;
    font-size: 0.825rem;   
    font-weight: 700; 
}

.rst-pass {
    color: #542E92;
    font-family: "UniNeue-bold", Sans-serif;
    font-style: normal;
    cursor: pointer;
}

.rst-pass:hover {
    color: #542E92;
    text-decoration: none;
}

.login-btn {
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    border-radius: 12px;
    border: none;
}

.login-btn:focus,
.login-btn:active {
    outline: none !important;
    box-shadow: none;
}

.col-2-head {
    width: 46%;
    height: 100%;
    margin-top: -500px;
    margin-left: 370px;
    background-color: #C2CDEF;
}

.login-img-head {
    /* width: 90%; */
    width: 66%;
    margin-left: 16px !important;
    margin-bottom: 4px !important;
}
.login-3d-img {
    height: 400px;
    margin-left: 8%;
}

.carousel {
    margin-top: -17px;
}

.login-stay-touch {
    font-family: "UniNeue-Bold", Sans-serif;
    font-size: 2.188rem;
    font-style: normal;
    font-weight: 400;
    color: #FFF;
    margin-left: 10px !important;
}

.login-stay {
    font-family: "UniNeue-Regular", Sans-serif;
    font-size: 2.188rem;
    font-style: normal;
    font-weight: 400;
    color: #FFF;
    margin-left: 16px !important;
}

.login-stay-touch-sub {
    color: #dbd7e3;
    font-size: 0.79rem;
    font-family: "UniNeue-Regular", Sans-serif;
    margin-left: 16px !important;
    width: 60%;
    overflow: hidden;
    line-height: 20px;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
}

.carousel-indicators li {
    display: inline-block;
    width: 0.7rem;
    height: 1.3rem;
    background: rgba(255, 255, 255, 0.5);
    background-clip: content-box;
    border: 0.25rem solid transparent;
    border-radius: 9px;
    transition: transform 0.2s;
    margin-right: 207px;
    margin-left: -207px;
}

.carousel-indicators .active {
    display: inline-block;
    width: 0.7rem;
    height: 2.3rem;
    margin-top: -5px;
    background-color: #E2E8F0;
    background-clip: content-box;
    border: 0.25rem solid transparent;
    border-radius: 9px;
    transition: transform 0.2s;
}

/* Mobile View */
@media screen and (max-width: 1440px) {
    .my__carousel div ol {
        position: absolute;
        /* top: 75%;
        left: 70%;
        right: -60%; */
        bottom: -60px !important
    }
    .carousel-indicators .active {
        width: 0.7rem;
        height: 2.3rem;
        margin-top: -5px;
    }
}

@media screen and (max-width: 600px) {
    .main-box {
        width: 100%;
    }

    /* Login Carousel */
    .carousel {
        margin-top: 20px;
    }


    .login-img-head {
        width: 90%;
    }

    .login-3d-img {
        align-items: center;
        width: 100%;
        height: 250px;
        margin-left: 0;
    }

    .login-stay-touch-sub {
        font-size: 1.30rem !important;
    }

    .loading-img {
        width: 30px;
    }

    .column-1 {
        border-top-right-radius: 50px;
    }
}