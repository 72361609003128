.filter-row{
    /* width: 80rem; */
    gap:2rem
}
.claim-filter{
    margin-left: 0px !important;
}
.paginator{
    bottom: 0;
    position: absolute;
}
.summary-table{
    max-height: 45vh;
}
.detail-div{
    margin-left: 19px;
    display: flex;
    gap: 5px;
    flex-direction: column;
}
.detail-field{
    display: flex;
    gap:5px;
    width:25%;
    /* align-items: center; */
}
.detail-field-approve{
    display: flex;
    gap:5px;
    width:23%;
    align-items: center;
}
.detail-head{
    font-weight: 700;
    font-size: 1.1rem;
    margin-bottom: 3px;
    margin-top: 7px;
}
.details-lbl{
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: .875rem;
    color: #1E293B;
}
.field-value{
    font-size: .875rem;
}
.details{
    display: flex;
    column-gap:20px;
    row-gap:10px;
    flex-wrap: wrap;
    border-bottom: var(--light-gray) solid 1px;
}
.popup-head{
    align-items: center;
    column-gap: 16px;
}
.popup-title{
    margin-bottom: 0px;
}
.t-head{
    overflow: hidden;
    white-space: nowrap;
}
@media only screen and (min-width: 1024px) {
    .claim-filter{
        margin-left: -75px !important;
    }
    .summary-table{
        max-height: 57vh;
    }
  }