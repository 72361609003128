.cfm-card {
  /* height: 36.5rem; */
  height: 83vh;
  width: 100%;
  background: linear-gradient(250.32deg, #e9eaee 13.13%, #e4e6eb 86.79%),
    #ebecf0;
  background-blend-mode: soft-light, normal;
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5),
    4px 4px 8px rgba(51, 65, 85, 0.26);
  border-radius: 20px;
  /* overflow-y: auto; */
}

.hide-component {
  background: linear-gradient(250.32deg, #e9eaee 13.13%, #e4e6eb 86.79%),
    #ebecf0;
  height: 5px;
  width: 3px;
}

.performance-inside-scrollbar {
  overflow-y: auto;
  /* height: 30.5rem; */
  height: 70vh;
}

.feedback-inside-scrollbar {
  overflow-y: auto;
  height: 60vh;
  /* height: 27rem; */
}

.cfm-subcard {
  width: 100%;
  background: #ffffff;
  box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0;
  border-radius: 10px
}


.feedback-subcard {
  width: 100%;
  background: #ffffff;
  box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0;
  border-radius: 10px;
  padding: 8px 0px;
  min-height: 5rem;
  max-height: 15rem;
  overflow-y: auto;
}

.cfm_label {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.375rem;
  line-height: 30px;
  color: #1e293b;
}

.filter-btn {
  height: 1.875rem;
  /* width: 5.875rem; */
  background: linear-gradient(138.32deg,
      rgba(0, 0, 0, 0.5) 8.26%,
      rgba(255, 255, 255, 0.5) 91.02%),
    #f1f5f9;
  background-blend-mode: soft-light, normal;
  border: 1px solid rgba(255, 255, 255, 0.4);
  box-shadow: -2px -2px 8px #f2f4f8, 3px 3px 8px #e2e8f0;
  border-radius: 12px;
}

.filter-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  line-height: 1rem;
  font-size: 0.825rem;
  color: #1e293b;
}

.filter-img {
  height: 1rem;
  width: 1rem;
}

.feedback_button_text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  text-transform: uppercase;
}

.feedback-sub-circle {
  width: 36px;
  height: 36px;
  border: 1px solid #ffffff;
  box-sizing: border-box;
  border-radius: 40px;
}

.view-feedback-btn {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 16px;
  color: #3d1d76;
  margin-left: 1.5rem !important;
}

.circle-text {
  position: absolute;
  left: 4.2px;
  width: 38.5px !important;
  height: 38.5px !important;
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  top: -0.2px;
  border-radius: 100%;
  font-size: 0.875rem;
  color: #ffffff;
  z-index: 2;
  padding: 10px 10px;
}

.cfm-hide-goals-view {
  margin-right: -1rem;
  border-radius: 50%;
  padding: 7px;
  width: 36px !important;
  height: 36px !important;
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  text-align: center;
  justify-content: center;
  color: white !important;
  border: 1px solid white;
}

.fem-bg-circle {
  background-color: #3d1d76 !important;
}

.dem-bg-circle {
  background-color: #3a83f8 !important;
}

.employee-bg-circle {
  background-color: #65d283 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
  align-items: center;
}

.hint-btn-view {
  justify-content: flex-end !important;
  display: flex;
  margin-right: 10px !important;
  padding-right: 2px !important;
  padding-bottom: 2px !important;
  border-radius: 0px 0px 10px 10px;
  /* box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0; */
}

.hint-btn {
  cursor: pointer;
  margin-top: -5px;
  /* width: 10%; */
}

.hint-text-new {
  margin-right: 2px;
  font-family: "UniNeue-Normal", Sans-serif;
  font-style: normal;
  cursor: pointer;
  font-weight: 700;
  font-size: 0.775rem;
  text-align: center;
  justify-content: center;
  color: #3D1D76;
  box-sizing: border-box;
  flex-direction: row;
  padding: 2.5px 0px 15px;
  width: 50px;
  height: 22px;
  border: 1px solid #C6AAFA;
  border-radius: 8px;
}

/* .hint-text-new:hover{
  color: #1e293b;
  cursor: pointer;
  font-size: 1rem;
  border-bottom: 1px solid #64748b;
  margin-right: 2px;
  font-family: "UniNeue-Normal";
  font-style: normal;
  font-weight: 700;
  text-align: center;
  justify-content: center;
} */

.circle-text1 {
  position: absolute;
  left: -16.5px;
  width: 38px;
  height: 38px;
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  text-align: center;
  top: 15px;
  border-radius: 50%;
  font-size: 0.875rem;
  color: #ffffff;
  z-index: 2;
  padding: 8px 10px;
}

.time-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 16px;
  text-align: right;
  color: #64748b;
}

.feedback-comment-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 16px;
  text-align: left;
  color: #64748b;
  width: 80%;
}

.icon {
  /* position: absolute; */
  width: 4px;
  /* right: 7%; */
  height: 4px;
}

.sub-card-text-bold {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: #1e293b;
}

.sub-card-text-light {
  font-family: "UniNeue-Light", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.8rem;
  line-height: 19px;
  color: #64748b;
}

.badge-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: Bold;
  font-weight: 700;
  font-size: 0.75rem;
  line-height: 16px;
  color: solid #000000;
}

.feedback-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-left: -5px;
  font-size: 0.938rem;
  line-height: 20px;
  color: #1e293b;
}

.plus-img {
  height: 20px;
  width: 20px;
}

.tag-back {
  position: absolute;
  height: 24px;
  margin-top: 0.5px;
  margin-left: -4px;
  margin-bottom: 5px;
  background: #e2e8f0;
  border-radius: 10px;
}

.tag-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  margin: 0px 10px;
  margin-top: 4%;
  font-size: 0.75rem;
  color: #1e293b;
}

.sub-textarea {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 19px;
  color: #64748b;
}

.suggetion {
  background-color: white !important;
  width: 100%;
  border: 2px solid #E2E8F0 !important;
  border-top-color: transparent !important;
  border-right-color: #E2E8F0 !important;
  border-left-color: #E2E8F0 !important;
  border-bottom-color: #E2E8F0 !important;
}

.feedback-comment {
  border: 2px solid #E2E8F0 !important;
  border-color: #E2E8F0;
  border-top-color: #E2E8F0 !important;
  border-left-color: #E2E8F0 !important;
  border-right-color: #E2E8F0 !important;
  border-bottom-color: transparent !important;
  padding: 5px !important;
  resize: none;
}

.done-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.75rem;
  text-align: right;
}

.basedline {
  width: 100%;
  margin-top: -5px;
}

.inside-basedline {
  width: 95%;
}

.popup-category-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #1e293b;
}

/* Toggle CSS */
.deactivate-button-left {
  width: 50%;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(174, 174, 192, 0.5);
  border-radius: 12px 0px 0px 12px;
  text-align: center;
  font-family: "UniNeue-bold", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.813rem;
  align-items: center;
  color: #64748b;
  cursor: pointer;
  height: 36px;
}

.deactivate-button-right {
  width: 50%;
  background: #ffffff;
  box-shadow: 4px 4px 4px rgba(174, 174, 192, 0.5);
  border-radius: 0px 12px 12px 0px;
  text-align: center;
  font-family: "UniNeue-bold", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.813rem;
  align-items: center;
  color: #64748b;
  cursor: pointer;
  height: 36px;
}

.activate-button-left {
  width: 50%;
  background: #edf1ff;
  box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5), inset 2px 2px 4px #c2cdef;
  border-radius: 12px 0px 0px 12px;
  height: 36px;
  text-align: center;
  font-family: "UniNeue-bold", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.813rem;
  align-items: center;
  color: #3d1d76;
  cursor: pointer;
}

.activate-button-right {
  width: 50%;
  background: #edf1ff;
  box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5), inset 2px 2px 4px #c2cdef;
  border-radius: 0px 12px 12px 0px;
  height: 36px;
  text-align: center;
  font-family: "UniNeue-bold", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 0.813rem;
  align-items: center;
  color: #3d1d76;
  cursor: pointer;
}

.send-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  color: #3d1d76;
  flex: none;
  order: 1;
  flex-grow: 0;
  margin: 0px 8px;
}

.send-img {
  position: inherit;
  height: 17.98px;
  width: 18px;
  left: 66.5%;
}

.send-img1 {
  height: 17.98px;
  width: 18px;
}

.feedback-textarea-container {
  background: #ffffff;
  border: 1px solid #e2e8f0;
  box-sizing: border-box;
  border-radius: 6px;
}

/* The actual timeline goal (the vertical ruler) */

.timeline-goal {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.timeline-goal::after {
  content: "";
  position: absolute;
  width: 1.2px;
  background-color: #94a3b8;
  top: 0;
  bottom: 0;
  left: 5.7%;
  margin-left: -3px;
}

.timeline-goal1 {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.timeline-goal1::after {
  content: "";
  position: absolute;
  /* width: 1.2px; */
  background-color: #94a3b8;
  top: 0;
  bottom: 0;
  left: 5.2%;
  margin-left: -3px;
}

/* The actual timeline performance (the vertical ruler) */
.timeline {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

.timeline1 {
  position: relative;
  max-width: 1200px;
  margin: 0 auto;
  width: 100%;
}

/* The actual timeline (the vertical ruler) */
.timeline::after {
  content: "";
  position: absolute;
  width: 1.2px;
  background-color: #94a3b8;
  top: 0;
  bottom: 0;
  /* padding: 0px 1px 0px 1px; */
  left: 5%;
  margin-left: -3px;
}

.timeline1::after {
  content: "";
  position: absolute;
  /* width: 1.2px; */
  background-color: #94a3b8;
  top: 0;
  bottom: 0;
  /* padding: 0px 1px 0px 1px; */
  left: 5%;
  margin-left: -3px;
}

/* Container around content */
.container1 {
  padding: 13px 25px;
  position: relative;
  background-color: inherit;
  width: 95%;
}

/* The circles on the timeline */
.container1::after {
  content: "";
  position: absolute;
  width: 38px;
  height: 38px;
  border: 1px solid #94a3b8;
  top: 0px;
  left: 5px;
  border-radius: 50%;
  z-index: 1;
}

.container2::after {
  content: "";
  position: absolute;
  width: 44px;
  height: 44px;
  left: 0.3%;
  top: -3px;
  background-color: white;
  border: 1px solid #94a3b8;
  box-sizing: border-box;
  border-radius: 50%;
  z-index: 1;
}

/* Place the container to the left */
.left {
  left: 0;
}

/* Place the container to the right */
.right {
  left: 9.2%;
}

.h-liness {
  position: absolute;
  height: 0px;
  width: 70px;
  left: 10px;
  top: 20px;
  border: 1px solid #94a3b8;
}

/* Fix the circle for containers on the right side */
.right::after {
  left: -54px;
}

/* The actual content */
.content {
  position: relative;
  margin-top: -3%;
  background: #ffffff;
  box-shadow: -2px -2px 12px #f1f5f9, 4px 4px 12px #e2e8f0;
  border-radius: 10px;
  right: 5%;
}

.infoIconleadership {
  margin-left: 10px;
  height: 20px;
  width: 20px;
}

.leadershipPopupContent-text {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  color: #000000;
}

.leadershipPopupDescription {
  width: 87% !important;
  margin-left: 0px;
  padding-right: 10px !important;
}

.leadershipPopupLevel {
  width: 11% !important;
  margin-left: 15px !important;
}

.leadershipPopupInnerContainer {
  margin-top: 9px;
  margin-top: 5px;
  display: flex;
}

/* Media queries - Responsive timeline on screens less than 600px wide */
@media screen and (max-width: 600px) {

  /* Place the timelime to the left */
  .timeline::after {
    left: 31px;
  }

  /* Full-width containers */
  .container {
    width: 100%;
    padding-left: 70px;
    padding-right: 25px;
  }

  /* Make sure that all arrows are pointing leftwards */
  .container::before {
    left: 60px;
    border: medium solid white;
    border-width: 10px 10px 10px 0;
    border-color: transparent white transparent transparent;
  }

  /* Make sure all circles are at the same spot */
  .left::after,
  .right::after {
    left: 15px;
  }

  /* Make all right containers behave like the left ones */
  .right {
    left: 0%;
  }
}

@media screen and (max-width: 760px) {
  .feedback {
   margin-top: 20px;
  }
}

.Achievement {
  grid-area: team-one;
}

.feedback {
  grid-area: team-two;
}