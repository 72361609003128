.justify-content-evenly {
    display: flex;
    justify-content: space-evenly;
}

.justify-content-around {
    display: flex;
    justify-content: space-around;
}

.justify-content-between {
    display: flex !important;
    justify-content: space-between;
}

.justify-content-end {
    display: flex;
    justify-content: end;
}

.justify-content-start {
    display: flex;
    justify-content: start;
}

.justify-content-center {
    display: flex;
    justify-content: center;
}

.justify-content-center-noflex {
    justify-content: center;
}

.justify-content-unset {
    justify-content: unset;
}

.justify-self-end {
    justify-self: end;
}

.align-self-center {
    display: flex;
    align-self: center;
}

.align-self-end {
    display: flex;
    align-self: end;
}

.align-items-baseline {
    align-items: baseline;
}

.align-items-flex-end {
    align-items: flex-end;
}

.align-item-center {
    display: flex;
    align-items: center;
}

.align-item-center-noflex {
    align-items: center;
}

.text-align-left {
    display: flex;
    text-align: left;
}

.text-align-center1 {
    display: flex;
    text-align: center;
}

.text-align-center-noflex {
    text-align: center;
}

.flex-row-reverse {
    display: flex;
    flex-direction: row-reverse;
}

.flex-start {
    justify-content: flex-start;
}

.flex-direction-column {
    display: flex;
    flex-direction: column;
}

.flex-direction-row {
    display: flex;
    flex-direction: row;
}
.flex-wrap{
    flex-wrap: wrap;
}

.flex_2 {
    flex: 0.2;
}

.flex_8 {
    flex: 0.8;
}

.text-align-center {
    text-align: center !important;
}

.flex_1 {
    flex: 1;
}

.flex-flow {
    flex-flow: wrap;
}

.flex-wrap {
    flex-wrap: wrap;
}

.flex-basis-100 {
    flex-basis: 100%;
}

.flex-basis-50 {
    flex-basis: 50% !important;
}

.flex-grow-revert {
    flex-grow: revert-layer;
}

.dflex {
    display: flex;
}

.justify-content-center-with-grid {
    display: grid;
    justify-content: center;
}

.shifttoend {
    flex: 1;
    justify-content: end;
}

.align-item-centerjustify-content-center {
    align-items: center;
    justify-content: center;
}

.flex {
    display: flex;
}

.bottom-right {
    position: absolute;
    bottom: 0px;
    right: 0px;
}

.word-wrap {
    word-wrap: break-word;
}

.white-space-no-wrap {
    white-space: nowrap;
}

.display-grid {
    display: grid;
}

.overflow-y-hidden {
    overflow-y: hidden;
}