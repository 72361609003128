.pending {
    color: #E3B203 !important;
}

.completed {
    color: #6A934A !important;
}

.revoked {
    color: #FF5353 !important;
}

.reopen {
    color: #4c00b0 !important;
}

.gap-10px {
    gap: 10px;
}

.gap-10px-imp {
    gap: 10px !important;
}

.right-top{
    position: absolute;
    right: -2px;
    top:14px
}
.emp-container {
    display: flex;
    /* column-gap: 20px; */
    row-gap: 10px;
    flex-wrap: wrap;
}

.accordion-padding {
    padding: 0 2%;
}

.accordion-margin {
    margin-left: 0.5rem;
}

.border-completed {
    border-left: 0.5px solid #6A934A;
}

.border-pending {
    border-left: 0.5px dotted #A2A2A2;
}

.completed-title {
    color: #6A934A;
}

.pending-title {
    color: #A2A2A2;
}

.clearance-table {
    height: 60vh;
    display: flex;
    flex-direction: column;
    overflow: auto;
    margin-top: 10px;
}

.clearance-table-half {
    max-height: 38vh;
}

.scroll-auto {
    overflow-y: auto;
}

.scroll-hidden {
    overflow-y: hidden;
}

.filter-top-border {
    border-top: 0.5px solid #3d1d7673;
    padding-top: 0.5rem;
}

.search-box-clearance {
    display: flex;
    align-items: center;
    padding-left: .5rem;
    height: 44px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.search-box-input {
    -webkit-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    border: none;
    box-sizing: border-box;
    width: 9rem;
}

.search-box-input:focus {
    outline: none;
}

.search-box-icon {
    -webkit-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
}

.confirmation-message {
    font-size: 1rem !important;
    text-wrap: wrap;
    font-weight: 500;
}

.border-none{
    border: none;
}
.bg-none{
    background: none;
}
@media only screen and (min-width: 992px) {
    /* .clearance-table {
        max-height: 47vh;
    } */
    .emp-clearance-table {
        max-height: 47vh;
    }
}

/* @media only screen and (min-width: 1200px) {
    .clearance-table {
        min-height: 47vh;
        max-height: 48vh;
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin-top: 10px;
    }
} */

/* @media only screen and (min-width: 1440px) {
    .clearance-table {
        height: 40vh;
        display: flex;
        flex-direction: column;
        overflow: auto;
        margin-top: 10px;
    }
} */