.header-right-icon {
  /* width: 1.75rem;
    height: 2.375rem; */
  overflow: 'contain';
  /* margin: 0.563rem; */
  /* padding: 0px; */
  /* margin-left: 0px; */
  cursor: pointer;
  border-radius: 50%;
}

.outer-header {
  display: flex;
  flex-direction: column;
  height: 5.0rem;
  padding-left: 1.625rem;
  padding-right: 1.563rem;
  border-radius: 0px 0px 20px 20px;
  background: linear-gradient(250.32deg, rgba(203, 213, 225, 0.6) 13.13%, rgba(226, 232, 240, 0.6) 86.79%), #F8FAFC;
  /* background: linear-gradient(to right, rgba(239, 240, 243, 0.6) 13.13%, rgba(234, 235, 239, 0.9) 89.79%), #F0F1F5; */
  background-blend-mode: soft-light, normal;
  border: 1px solid #F8FAFC;
  box-sizing: border-box;
  box-shadow: 6px 6px 6px rgba(71, 85, 105, 0.08);
}

.tipdiv {
  position: absolute;
  right: 0;
  margin-right: 60px;
  top: 2rem;
}

.tipdiv:after {
  position: absolute;
  margin-right: 10;
  bottom: -58px;
  left: 10px;
  content: "";
  background: #F1F5F9;
  transform: rotate(135deg);
  /* Prefixes... */
  height: 10px;
  border-left: 20px solid transparent;
  border-bottom: 20px solid transparent !important;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);

}

.tipnotification {
  position: absolute;
  right: 0;
  margin-right: 8.7%;
  top: 2rem;
}

.tipnotification:after {
  position: absolute;
  margin-right: 10;
  bottom: -58px;
  left: 10px;
  content: "";
  background: #F1F5F9;
  transform: rotate(135deg);
  /* Prefixes... */
  height: 10px;
  border-left: 20px solid transparent;
  border-bottom: 20px solid transparent !important;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(35, 35, 72, 0.26);

}

/* JSFiddle: https://jsfiddle.net/6t6kz4ws/ */
.welcome-text {
  font-family: 'UniNeue-Regular';
  font-style: normal;
  font-weight: 300;
  font-size: 1.3rem;
  line-height: 1.569rem;
  color: #64748B;
}

.header-text {
  font-family: 'UniNeue-Bold';
  font-style: normal;
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
  line-height: 1.572rem;
  color: #1E293B;
}

.header-text-t {
  font-family: 'UniNeue-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 1.1rem;
  text-transform: uppercase;
  line-height: 1.772rem;
  color: #64748B;
}

.route-common-heading {
  font-family: 'UniNeue-Regular' !important;
  font-style: normal;
  font-weight: 400;
  font-size: 1.325rem !important;
}

.emp-name {
  font-family: 'UniNeue-Regular';
  font-style: normal;
  font-weight: 700;
  font-size: 1.425rem;
  line-height: 2.188rem;
  color: #475569;
  margin-left: 0.563rem;
}

.moments-right-icon {
  cursor: pointer;
  margin-right: 1.5rem;
  width: 3.188rem;
  height: 2.093rem;
  left: 70.938rem;
  top: 1.798rem;
}

.birthday-right-icon {
  cursor: pointer;
  margin-right: 0.115rem;
  margin-bottom: 1.8rem;
  width: 4.563rem;
  height: 4.863rem;
}

.header-right-icon {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
}

.profile-sm-default {
  cursor: pointer;
  width: 2.5rem;
  height: 2.5rem;
}

.notification-icon {
  margin-right: 1.5rem;
  cursor: pointer;
  width: 1.875rem;
  height: 1.875rem;
}

.menu-icon {
  cursor: pointer;
}

.badge {
  background-color: blueviolet;
  font-size: 0.563rem;
  width: 1.563rem;
  height: 1.563rem;
  z-index: 100;
  border-radius: 0.781rem;
  color: white;
  display: flex;
  position: absolute;
  align-items: center;
  cursor: pointer;
  right: 75px;
}


.mobile {
  visibility: hidden;
  display: none;
}

.web600 {
  visibility: visible;
  display: block;
}

.search__control {
  width: 300px !important;
  padding: 0 5px;
  border-radius: 10px !important;
  margin-right: 1.5rem;
  width: 200px;
  box-shadow: -1px -1px 2px 0px #edf2ff inset, 2px 2px 4px 0px #c2cdef inset;
}

.search__control .search-icon {
  height: 18px;
}

.search__option {
  cursor: pointer !important;
}

@media (max-width: 1050px) {
  .tipnotification {
    margin-right: 112px;
    top: 1.8rem;
  }
}

@media (max-width: 700px) {
  .tipdiv {
    margin-right: 55px;
    top: 1.5rem;
  }

  .tipnotification {
    margin-right: 106px;
    top: 1.5rem;
  }
}



/* ////////////////---Mobile----//////////////// */
@media only screen and (max-width: 600px) {

  .web600 {
    visibility: hidden;
    display: none;
  }

  .mobile {
    visibility: visible;
    display: block;
    top: 0;
  }
}

@media only screen and (max-width: 400px) {
  .tipdiv {
    margin-right: 55px;
    top: 0.7rem;
  }

  .tipnotification {
    margin-right: 94px;
    top: 0.6rem;
  }
}