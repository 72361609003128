.document-logo {
    height: 2.779rem;
    width: 2.625rem;
    cursor: pointer;
}

.document-single-logo {
    height: 3.779rem;
    width: 3.625rem;
    cursor: pointer;
}

.incometax-single-logo {
    height: 3.625rem;
    width: 3.625rem;
    cursor: pointer;
}

.incometax-logo {
    height: 2.625rem;
    width: 2.625rem;
    cursor: pointer;
}

.document-text {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: #1E293B;
    cursor: pointer;
}

.mid-line {
    /* position: absolute; */
    /* width: 153px;
    height: 20px; */
    border: 1px solid #CBD5E1;
}

.incometax-text {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 0.875rem;
    color: #1E293B;
    width: 70px;
    cursor: pointer;
}

.edit-leave-card2 {
    /* height: 200px; */
    height: 100% !important;

}

@media (max-width: 767px) {
    .document-logo {
        height: 5.225rem;
        width: 4.625rem;
    }

    .incometax-logo {
        height: 5.225rem;
        width: 4.625rem;
    }

    .edit-leave-card2 {
        height: 160px;
    }

}

@media (max-width: 576px) {
    .document-logo {
        height: 5.225rem;
        width: 4.625rem;
    }

    .incometax-logo {
        height: 5.225rem;
        width: 4.625rem;
    }

    .edit-leave-card2 {
        height: 150px;
    }

    .document-text {
        font-size: 1.115rem;
    }

    .incometax-text {
        font-size: 1.115rem;
    }
}