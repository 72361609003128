.container-project-details {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
}

.project-short-view {
    display: grid;
    grid-template-columns: auto auto;
    grid-template-rows: 1rem 1.5rem;
    grid-row-gap: 5px;
    grid-column-gap: 15px;

}

.selected-tab {
    background-color: #E2E8F0;
    border: none;
}

.mr_6px {
    margin-right: -6px;
}

.right-arrow {
    height: 27px;
    width: 27px;
}

.grid-container-form {
    display: grid;
    grid-template-columns: auto auto auto;
}

.status-color-circle {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
}

.side-vertical-status-bar {
    height: 10vh;
    border-bottom-right-radius: 10px;
    border-top-right-radius: 10px;
    margin-left: 1px;
}

.card-style {
    padding: 5px 5px 5px 0px;
    border-radius: 15px !important;
}

.pg-project-view {
    display: grid;
    grid-template-columns: auto auto auto auto;
}

.applicant-main {
    display: grid;
    grid-template-columns: 0.9fr 2fr;
    height: 58.2vh;
}

.container-table {
    display: grid;
    grid-template-columns: 5rem 15rem 15rem 10rem 12rem 12rem 10rem;
    grid-row-gap: 1px;
    overflow-x: auto;
}

.container-gems {
    white-space: nowrap;
    overflow-x: auto;
    flex-wrap: wrap;
    display: flex;
}

.content {
    word-wrap: break-word;
    max-width: 100%;
}

.table-action-button {
    width: 25px;
    cursor: pointer;
}

.gems-edit {
    margin-left: 20px;
}

.edit-icon {
    width: 25px;
    height: 100%;
}

.edit-icon img {
    width: 100%;
    height: 100%;
}

@media (max-width: 1342px) {
    .project-short-view {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1rem;
        grid-row-gap: 1px;
        grid-column-gap: 5px;
    }

    .appcenter-app-name {
        font-family: 'UniNeue-Bold', Sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.65rem;
        line-height: 1.188rem;
        margin-top: 9px;
        color: #1E293B;
        justify-content: center;
    }

    .feedback-sm-text {
        font-family: 'UniNeue-Regular', Sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.65rem;
        line-height: 1rem;
    }

    .right-arrow {
        height: 27px;
        width: 27px;
    }

    .p-lr {
        padding-left: 20px;
        padding-right: 20px;
    }
}

@media (max-width:700px) {
    .arrow-hide {
        display: none;
    }
}

.container-employee-table {
    display: grid;
    grid-template-columns: 5rem 10rem 10rem 10rem 10rem 10rem 6rem;
    grid-row-gap: 1px;
    overflow-x: auto;
}

.gems-cv-card {
    border: 1.5px solid var(--white);
    box-sizing: border-box;
    border-radius: 0.625rem;
    background: var(--white);
    box-shadow: -2px -2px 12px var(--light-gray-2), 4px 4px 12px var(--light-gray-3);
}

.grid-color-status {
    display: grid;
    grid-template-columns: auto auto auto auto auto auto auto;
    padding-left: 5px;
    padding-right: 5px;
    overflow-x: auto;
}

@media (max-width: 600px) {
    .project-short-view {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1rem;
        grid-row-gap: 1px;
        grid-column-gap: 5px;
    }

    .applicant-main {
        display: grid;
        grid-template-columns: 1fr;
        grid-row-gap: 10px;
        height: 54vh;
    }

    .container-table {
        display: grid;
        grid-template-columns: auto auto auto auto auto auto auto;
        grid-row-gap: 1px;
    }
}

@media (max-width: 1000px) {
    .project-short-view {
        display: grid;
        grid-template-columns: auto auto;
        grid-template-rows: 1rem;
        grid-row-gap: 1px;
        grid-column-gap: 5px;
    }

    .appcenter-app-name {
        font-family: 'UniNeue-Bold', Sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.55rem;
        line-height: 1.188rem;
        margin-top: 9px;
        color: var(--common-black);
        justify-content: center;
    }

    .feedback-sm-text {
        font-family: 'UniNeue-Regular', Sans-serif;
        font-style: normal;
        font-weight: 400;
        font-size: 0.55rem;
        line-height: 1rem;
    }

    .right-arrow {
        height: 20px;
        width: 20px;
    }

    .p-lr {
        padding-left: 10px;
        padding-right: 10px;
    }
}

.active-status {
    border-radius: 10px 10px 10px 10px;
    margin-left: 10px;
}

.grid-status {
    display: grid;
    grid-template-columns: auto auto auto auto;
    grid-column-gap: 15px
}

.back-button {
    position: absolute;
    right: 40px;
    bottom: 70px;
}

.excel-image {
    height: 30px;
    width: 30px;
}

.download-image {
    height: 50px;
    width: 50px;
}

.gems-approve-popup {
    height: 68%;
    width: 35%;
}

.container-landing {
    display: grid;
    grid-template-columns: 1.3fr 0.5fr;
    grid-row-gap: 1px;
    overflow-x: auto;
    height: 72vh;
}

.landing-vertical {
    display: grid;
    grid-template-rows: 1fr 0.8fr 0.1fr;
    grid-row-gap: 10px;
    overflow-x: auto;
}

.custom-back-button {
    border: 1px solid var(--dark-gray-1);
    border-radius: 8px;
    background-color: var(--white);
    color: var(--common-black);
    font-weight: 700;
}

.landing-profile {
    display: grid;
    grid-template-rows: auto auto;
    grid-row-gap: 1px;
    overflow-x: auto;
}

.right-arrow-gif {
    cursor: pointer;
    width: 1.5rem;
    height: 1.5rem;
}

.container-landing-details {
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    grid-row-gap: 1px;
    overflow-x: auto;
}

.profile-header {
    cursor: pointer;
    width: 3.5rem;
    height: 3.5rem;
    overflow: 'contain';
    cursor: pointer;
    border-radius: 50%;
}

.date-input {
    height: 44px;
    width: 100%;
    background-color: var(--white);
    border: 1px solid var(--gray-shade) !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px var(--dark-gray), inset -1px -1px 2px var(--light-gray-1);
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: var(--light-gray);
}

.admin-gems-main {
    display: grid;
    grid-template-columns: 1fr 2fr;
    height: 63vh;
}

.admin-gems-description-title {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.65rem;
    line-height: 1.188rem;
    color: #1E293B;
    justify-content: center;
}

.more-style {
    color: var(--white);
}

.noProject-style {
    background-color: var(--silver);
    border-radius: 8px;
}

.project-list-style {
    color: var(--violet);
}

table {
    width: 100%;
    border-collapse: collapse;
}

.approval-popup {
    display: grid;
    grid-template-columns: auto auto;
}

.admin-gems-info-icon {
    height: 20px;
    width: 20px;
}

.overflow-gems-fields {
    overflow-y: scroll;
    max-height: 50vh;
}

.tool-tip-font-gems {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.675rem;
    color: #0f0f0f;
    background-color: #CBD5E1;
    border-radius: 20px;
    width: 27rem;
    z-index: 1002;
    padding: 10px;
}

.tool-tip-font-gems-applicant {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.675rem;
    color: #0f0f0f;
    background-color: #CBD5E1;
    border-radius: 20px;
    width: 23rem;
    z-index: 1002;
    padding: 10px;
}

.image-styles {
    height: 10rem;
}

.bottom-buttons-styles {
    position: fixed;
    bottom: 4px;
}

.dropdown-text-gems {
    margin-right: 1.875rem;
    width: 12.5rem
}

.h-83vh {
    height: 83vh;
}
.h-95vh {
    height: 95vh;
}

.back-btn-styles {
    position: absolute;
    right: 36px;
    bottom: 22px;
}

.status-background-styles {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 12px 10px 12px 0px;
    background: #E2E8F0;
    border-radius: 12px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 20px;
    color: #1E293B;
}

.f-gap-8rem {
    gap: 8rem;
}

.f-gap-1rem {
    gap: 1rem;
}

/* ----------PG Project Details Grid------------- */
.pg-project-grid {
    display: grid !important;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-rows: 1fr;
    /* width: 100%; */
    /* height: 50vh !important; */
    grid-area:
        'pgProjectFirst pgProjectSecond pgProjectThird pgProjectFourth'
        'pgProjectFifth  pgProjectFifth  pgProjectFifth  pgProjectFifth'
    ;
    grid-gap: 20px;
}

.pg-project-first {
    grid-area: pgProjectFirst;
}

.pg-project-second {
    grid-area: pgProjectSecond;
}

.pg-project-third {
    grid-area: pgProjectThird;
}

.pg-project-fourth {
    grid-area: pgProjectFourth;
}

.pg-project-fifth {
    grid-area: pgProjectFifth;
}

@media (max-width: 768px) {
    .pg-project-grid {
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'pgProjectFirst'
            'pgProjectSecond'
            'pgProjectThird'
            'pgProjectFourth'
            'pgProjectFifth'
        ;
        grid-gap: 20px;
    }
}

@media (max-width: 576px) {
    .pg-project-grid {
        display: grid !important;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr;
        grid-template-areas:
            'pgProjectFirst'
            'pgProjectSecond'
            'pgProjectThird'
            'pgProjectFourth'
            'pgProjectFifth'
        ;
        grid-gap: 20px;
    }
}

.webkit-width {
    width: -webkit-fill-available;
}

/* ----------PG MTR/FR Details Grid------------- */

.pg-MTRFR-grid {
    display: grid !important;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr;
    /* width: 100%; */
    /* height: 50vh !important; */
    grid-template-areas:
        'pgMTRFirst pgMTRSecond'
        'pgMTRFirst pgMTRSecond'
        'pgMTRFirst pgMTRSecond'
        'pgMTRFirst pgMTRSecond'
    ;
    grid-gap: 10px;
}

.pg-MTR-first {
    grid-area: pgMTRFirst;
}

.pg-MTR-second {
    grid-area: pgMTRSecond;
}

.model-img-gems {
    width: 25rem;
    height: 25rem;
    border-radius: 190px;
}

.close-btn-styles {
    display: flex;
    justify-content: center;
    margin-left: 12rem;
}

.hidecheckBox {
    visibility: hidden;
}

.check-mark-gems {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #f8f9fa;
    border: 1px solid #d7dee8;
}

.boxx:hover input+.check-mark-gems {
    background-color: #1c87c9;
}

.checkBoxData input:checked+.check-mark-gems {
    background-color: #3d1d76;
}

.check-mark-gems:after {
    content: "";
    position: absolute;
    display: none;
}

.checkBoxData input:checked+.check-mark-gems:after {
    display: block;
}

.checkBoxData .check-mark-gems:after {
    left: 6px;
    bottom: 4px;
    width: 6px;
    height: 11px;
    border: solid #eee;
    border-width: 0px 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.serchIconSize-gems{
    z-index: 1;
    position: relative;
    right: 3rem;
    top: 7px;
}

.gap30px {
    display: flex;
    gap: 30px;
}

.gap50px {
    display: flex;
    gap: 50px;
}

.arrow-right-and-count{
    padding: 5px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
    flex-direction: column;
    background-color: #3d1d76;
    border-radius: 15px;
}
.application-pending-count{
    font-size: 12px;
    font-weight: bold;
    color: #3d1d76;
    background-color: white;
    padding: 0px 6px;
    border-radius: 47px;
}