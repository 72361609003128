.announcement-img {
    height: 14.438rem;
    /* width: 17.5rem; */
    border-radius: 8px;
    max-width: 16rem;
}

.card-item {
    overflow-y: auto;
    height: 70vh;
}

hr {
    width: 99%;
}

.pin {
    cursor: pointer;
    width: 1.375rem;
    height: 1.375rem;
}

.portal-emoji {
    cursor: pointer;
}

.leftrightgo {
    cursor: pointer;
    height: 1.25rem;
    width: 1.25rem;
}

.previouseBtn {
    background-color: aqua;
    color: #3D1D76;
}

.pagenoBox {
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB;
    border-radius: 8px;
    font-size: 0.875rem;
    font-style: normal;
    width: 40%;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    padding: 3px;
    outline: none;
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 800;
    color: #1E293B;
}

.tata-buzz-news {
    height: 47% !important;
}

.occasionscroll {
    overflow-y: auto;
    height: 27vh;
    padding-bottom: 0px !important;
}

.news-scroll {
    overflow-y: auto;
    height: 200px;
}

.upcoming-occasion {
    height: 50% !important;
}

.uppin-img {
    height: 5.5rem;
    width: 10.5rem;
    border-radius: 8px;
}

.pin-data-bgproperty {
    background: #F1F5F9;
    border-radius: 6px;
}

.model-img {
    width: 35.688rem;
    height: 29.569rem;
    border-radius: 8px;
}

.modal-content {
    background-color: transparent;
    border: none !important;
}

.mdl-content {
    background-color: #F1F5F9;
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 550px !important;
        margin: 1.75rem auto !important;
    }
}

.white-cross {
    width: 2.5rem;
    height: 2.5rem;
    cursor: pointer;
}

.modal-body {
    padding: 0rem !important;
}
.modal-body-padd {
    padding: 0.5rem !important;
}

.mdl-body {
    padding: 5px !important
}

.emoji-custom {
    width: 1.5rem;
    height: 1.5rem;
}

.emoji-emp-reaction {
    width: 1.8rem;
    height: 1.8rem
}

.count-custom {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #3D1D76;
}

.announcement-inner-circle {
    width: 30px;
    height: 30px;
    border: 1px solid #94a3b8;
    box-sizing: border-box;
    border-radius: 40px;
    outline: 1px solid #94a3b8;
    outline-offset: 3px;
}

.announcement-circle-text {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #FFFFFF;
}

.showmore {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 19px;
    color: #3D1D76;
}

.occasionlogo {
    width: 1.875rem;
    height: 1.875rem;
}

.borderthickness-selected {
    border-top: 3px solid #3d1d76 !important;
}

.borderthickness-non-selected {
    border-top: 3px solid transparent !important;
}

.hr-outerline {
    margin-top: -2px !important;
}

.circle {
    border-width: 2px;
    border-radius: 15px;
    height: 25px;
    width: 25px;
    background-color: #623AAA;
    text-align: center;
}

.text-in-cicle {
    color: #FFFFFF;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

.text-in-cicle-deselect {
    color: #000000;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
}

.bottom-radius {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
}

.reaction-page-popup {
    height: 50vh;
    overflow-y: auto;
}

.reaction-cicle-icon {
    margin-top: 0.28rem;
    margin-bottom: 0.28rem;
    margin-left: 0.28rem;
    margin-right: 0.28rem;
}

.nodata-upcommingOccasion {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 180px;
}