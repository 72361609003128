.Toastify__close-button>svg {
    fill: currentColor !important;
    height: 25px !important;
    width: 30px !important;
    margin-top: 10px !important;
}

.toastSuccess {
    background: #5C8C36 !important;
    box-shadow: 0px 0px 16px rgba(92, 140, 54, 0.5) !important;
    border-radius: 12px !important;
    text-align: center !important;
}


.toastWarn {
    background: #E3B203 !important;
    box-shadow: 0px 0px 16px rgba(227, 178, 3, 0.5) !important;
    border-radius: 12px !important;
    text-align: center !important;
}

.toastError {
    background: #FF4949 !important;
    box-shadow: 0px 0px 16px rgba(255, 73, 73, 0.5) !important;
    border-radius: 12px !important;
    text-align: center !important;
}

@media screen and (max-width: 480px) {

    .toastSuccess,
    .toastWarn,
    .toastError {
        width: 80% !important;
        margin-left: 8% !important;
    }

    .Toastify__toast {
        margin-bottom: 25px !important;
    }
}