.inpt{
    background-color: #F2F4FB;
    border: 1px solid #F2F4FB;
    border-radius: 8px;
    font-size: 0.875rem;
    font-style: normal;
    width: 100%;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    padding: 7px;
    outline: none;
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 700;
    color: #1E293B;
}

textarea:focus, input:focus{
    outline: none;
}
.css-ax3k5r-control{
    border-radius: 14px !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}