.save-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    width: 165px;
    height: 40px;
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    border-radius: 12px;
}