.buttonStyle {
    background: linear-gradient(90deg, #3d1d76 0%, #653daf 100%);
    border-radius: 12px;
    color: #ffffff;
    font-size: 14px;
    font-family: "Unineue-Regular", Sans-serif;
    border: none;
}

.buttonStyle:hover {
    text-decoration: none;
    color: #fff;
}

.whiteButtonStyle {
    color: #1E293B;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #94A3B8;
    padding: 5px 20px;
    font-size: 14px;
    font-weight: 500;
    background-color: #FFF;
    float: right;
}

.disableButtonStyle {
    color: #FFF;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #94A3B8;
    padding: 5px 20px;
    background: linear-gradient(90deg, #a190e4 0%, #9d7ae2 100%);
    font-weight: 500;
    font-size: 14px;
    float: right;
}