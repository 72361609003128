.radio {
    height: 18px;
    width: 18px;
}

.radioTxt {
    font-size: 0.875rem;
    margin: 0 5px;
}

.save-btn {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    width: 95px;
    height: 40px;
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    border-radius: 12px;
}

.save-txt {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 140%;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: uppercase;
    color: #FFFFFF;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin: 0px 10px;
}

.thead {
    font-size: 0.875rem;
    text-overflow: ellipsis;
}

.h-18vh {
    overflow-y: scroll;
    height: 18vh;
}

.h-14vh {
    overflow-y: scroll;
    height: 14vh;
}

.h-21vh {
    overflow-y: scroll;
    height: 21vh;
}

.coi-summary-chart-text {
    color: #3D1D76;
    height: 45px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: k 700;
    font-size: 2rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    text-align: center;
}

.coiAdminDashboard {
    overflow-y: scroll;
    height: 41vh;
}

.completionDashboard {
    overflow-y: scroll;
    height: 37vh;
}

.group-width {
    width: 18% !important;
}

@media (max-width: 750px) {
    .group-width {
        width: 100% !important;
    }
}

@media (max-width: 450px) {
    .group-width {
        width: 100% !important;
    }
}