.background-filter{
    background: transparent;
}

.filter{
    flex-wrap: wrap;
    background: #E2E8F0;
    padding: 1rem;
    border-radius: 8px;
}

.filter-p5{
    flex-wrap: wrap;
    background: #E2E8F0;
    padding: 0.5rem 1rem ;
    border-radius: 8px;
}

.Evaluator-background-highlighter{
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-top: 2px;
    width: 99%;
    padding: 0px 20px 0px 20px;
    border-radius: 6px;
    line-height: 5px;
    color: #1E293B;
}