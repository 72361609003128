.coming-soon-container {
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.image-wrapper {
    margin-bottom: 20px;
}

.coming-soon-image {
    max-width: 100%;
    height: auto;
}

.text-wrapper {
    font-size: 1.5em;
    color: #777; /* Adjust color as needed */
}
