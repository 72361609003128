.firstContainer {
    display: flex;
    flex-direction: column;
}

.secondContainer {
    display: flex;
    flex-direction: row;
}

.buttom-margin {
    margin: 25px 10px 15px 10px !important
}

.scroller-header {
    overflow-x: auto;
}

.registration-body {
    position: relative;
    margin: 0 auto;
    background: #fff;
    border-radius: 4px;
    padding: 0 10px 10px;
    overflow: auto;
}

.bulk-registration-width {
    width: 35em;
    /* min-height: 53vh !important; */
}

.update-registration-width {
    width: 30em;
    /* min-height: 53vh !important; */
}

.single-registration-width {
    width: 30em;
}

.historyTable-height {
    max-height: 65vh;
    overflow-y: auto;
}

.registration-screen-placeholder {
    height: 42px;
    width: 100%;
    background-color: #FFFFFF;
    border: 1px solid #F2F4FB !important;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: #64748B;
}

.un-editable {
    background-color: #F2F4FB;
}

.registration-invalid {
    height: 42px;
    width: 100%;
    background-color: #FBF2F2;
    border: 1px solid #F2F4FB;
    border-radius: 12px;
    box-shadow: inset 2px 2px 4px #EFC2C2, inset -1px -1px 2px #EDF2FF;
    font-family: "UniNeue-Regular";
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    outline: none;
    color: #64748B;
}

.registration-screen-search {
    height: 42px;
    width: 100%;
    /* background-color: #F2F4FB; */
    /* border: 1px solid #F2F4FB !important; */
    border-radius: 5px;
    /* box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF; */
    font-family: 'UniNeue-Regular';
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    /* color: #64748B; */
}

.flex-style-for-view-button {
    flex-direction: row;
    display: flex;
    justify-content: center;
}

.errorScreenHeight {
    /* max-height: 50vh; */
    overflow-y: auto;
}

.searchIcon {
    height: 20px;
    width: 20px;
}

.viewButton {
    /* height: 25px; */
    width: 30px;
}

.view-Error {
    width: 45%;
    max-height: 50vh;
}

/* *pagination CSS* */
.react-bootstrap-table-pagination-list {
    text-align: end;
    transform: translateX(+300%);
}

.react-bootstrap-table-page-btns-ul {
    float: none;
}

.pre-next {
    font-size: 20px;
    margin-right: 20px;
    color: #3d1d76;
}

.purple {
    width: 35px;
    border-radius: 50%;
    /* background-color: #3d1d76; */
    color: 3d1d76;
}

.goToPage {
    margin-left: 70px;
    font-weight: 700;
    font-size: 14px;
    display: flex;
    align-items: center;
}

.goToPage input {
    border: 1px solid #CBD5E1;
    border-radius: 4px;
    height: 36px;
    width: 56px;
    margin-left: 10px;
    padding: 5px;
    text-align: center;
}

.btm-pagination {
    padding: 0 !important;
}

.btm-pagination .flex-style-for-view-button {
    background: #F1F5F9;
    border-radius: 0 0 20px 20px;
    border: 1px solid #dee2e6;
    border-top: 0;
    min-height: 50px;
    align-items: center;
}