.table-head {
    gap: 0.25rem;
}

.search-box {
    width: 11rem;
}

.input-date {
    width: 10rem;
}

.radio-group {
    margin: 0 2rem;
}

.seach-filter-button {
    height: 4rem;
    display: flex;
    justify-content: flex-end;
}

.filter-search-button {
    height: 4rem;
    padding-top: 25px;
}

.pt-super-admin {
    padding-top: 15px;
}