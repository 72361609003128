.App {
	text-align: center;
	height: 100vh;
}

html, body {
	height: 100vh;
	background-color: #E5E5E5 !important;
	margin: 0;
	padding: 0;
	width: 100%;
	/* height: 100%; */
 	overflow-y: hidden;
	/* overflow-x: hidden; */ 
	/* overflow-y: auto; */
	font-size: 16px;
	scroll-behavior: smooth;
}

::-webkit-scrollbar {
	width: 7px;
	height: 8px;  
	/* height for horizontal scroll */
	background: #ffff !important;
	border-radius: 8px;
}

::-webkit-scrollbar-track {
	box-shadow: inset 0 0 5px #ffff;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb {
	background: #CBD5E1;
	border-radius: 10px;
}

::-webkit-scrollbar-thumb:hover {
	background: #d7e3f1;
}

@media (min-width: 1900px) {
	html, body {
		font-size: 17px !important;
	}
}

@media (min-width: 1800px) {
	html, body {
		font-size: 16px !important;
		overflow-y: auto !important;
	}
}

@media (max-width: 1400px) {
	html, body {
		font-size: 15px !important;
		overflow-y: auto !important;
	}
}

@media (max-width: 1280px) {
	html, body {
		font-size: 14px !important;
		overflow-y: auto !important;
	}
}

@media (max-width: 900px) {
	html, body {
		font-size: 13px !important;
		overflow-y: auto !important;
	}
}
@media (max-width: 600px) {
	html, body {
		font-size: 12px !important;
		overflow-y: auto !important;
	}
}

@media (max-width: 400px) {
	html, body {
		font-size: 10px !important;
		overflow-y: auto !important;
	}
}

