.loader-container {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:  rgba(0, 0, 0, 0.5);
    display: flex;
    z-index:999999999999;
    justify-content: center;
}

.loader {
    align-self: center;
    width: 400px;
}

