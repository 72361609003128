.coi-card {
  background: linear-gradient(250.32deg, #e9eaee 13.13%, #e4e6eb 86.79%),
    #ebecf0;
  background-blend-mode: soft-light, normal;
  border: 1.5px solid #ffffff;
  box-sizing: border-box;
  box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5),
    4px 4px 8px rgba(51, 65, 85, 0.26);
  border-radius: 20px;
  width: 100%;
}

.coiDashboardHeight {
  height: 49.8vh !important;
  overflow-y: scroll;
  padding: 5px;
}

.card-heading {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1.125rem;
  line-height: 24px;
  color: #1E293B;
  ;
}

.tata-policy-link {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: #3D1D76;
}

.coi-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: #1E293B;
  ;
}

.scroller-coi {
  overflow-y: auto;
  height: 500px;
}

.coi-sub-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #475569;
}

.coi-option-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-left: 3.5%;
  font-size: 0.875rem;
  line-height: 20px;
  color: #475569;
}

.declaration_button_text {
  height: 40px;
  width: 235px;
  font-family: "UniNeue-Light", Sans-serif;
  font-weight: 700;
  font-style: normal;
  font-size: 0.875rem;
  text-transform: uppercase;
  line-height: 19px;
}

.checkbox-text {
  font-family: "UniNeue-Bold", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: #334155;
}

.checkbox-sub-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  margin-left: 9.3%;
  font-size: 0.875rem;
  line-height: 19px;
  color: #334155;
}

.back-button-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 22px;
  color: #3d1d76;
}

/*----Popup styles-----*/
.popup-title {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1.125rem;
  line-height: 24px;
  display: flex;
  align-items: center;
  color: #1e293b;
}

.popup-text {
  font-family: "UniNeue-Regular", Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 1rem;
  line-height: 24px;
  color: #1e293b;
}

.popup-confirm-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  width: 138px;
  height: 42px;
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  border-radius: 12px;
  font-family: 'UniNeue-Light', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 140%;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
}

.popup-submit-text {
  width: 166px;
  height: 22px;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #FFFFFF;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

.popup-submit-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  width: 226px;
  height: 42px;
  background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
  border-radius: 12px;
}

.popup-back-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 10px 30px;
  width: 95px;
  height: 40px;
  border: 1px solid #1E293B;
  box-sizing: border-box;
  border-radius: 12px;
}

.solid-border {
  border: 1px solid #cbd5e1;
}

.popup-button-background {
  height: 65px;
  background: #ffffff;
  box-shadow: 0px -1px 8px rgba(30, 41, 59, 0.16);
  border-radius: 0px 0px 10px 10px;
}

.popup-back-text {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 140%;
  display: flex;
  align-items: center;
  text-align: center;
  text-transform: uppercase;
  color: #1E293B;
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 10px;
}

/*----Forms styles-----*/
.form-heading-label {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  margin-left: 3%;
  font-size: 0.875rem;
  line-height: 19px;
  color: #334155;
}

.form-agree-txt {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #1E293B;
}

.forms-number-text {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #000000;
}

.type-bottom-line {
  width: 88px;
  height: 4px;
  background: #3D1D76;
  border-radius: 4px 4px 0px 0px;
}

.form-label-text {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 140%;
  color: #1E293B;
}

.input-background {
  background-color: #F2F4FB;
  border: 1px solid #F2F4FB;
  border-radius: 12px;
  font-size: 0.875rem;
  font-style: normal;
  height: 48px;
  width: 100%;
  box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
  padding: 7px;
  outline: none;
  font-family: "UniNeue-Regular", Sans-serif;
  font-weight: 500;
  color: #1E293B;
}

.form-inner-text {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 1rem;
  line-height: 140%;
  color: #64748B;
}

.inner-calender {
  background: #94A3B8;
}

.mleft10 {
  margin-left: 10%;
}

.my-scrol {
  overflow-y: auto;
}

/*----Dashboard styles-----*/
.dashboard-options-back {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;
  padding: 12px 20px 12px 20px;
  background: #E2E8F0;
  border-radius: 6px;
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 20px;
  color: #1E293B;
}

.horizontal-lines {
  border: 1px solid #CBD5E1;
}

.add-btn-text {
  font-family: 'UniNeue-Regular', Sans-serif;
  font-style: normal;
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 19px;
  color: #3D1D76;
}

.info_icon {
  margin-left: -40%;
  margin-top: 22%;
  height: 20px;
  width: 20px;
}

.info_icon_resolution {
  height: 20px;
  width: 20px;
}