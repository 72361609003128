.popup-placeholder {
    height: 43px;
    background: var(--gray-shade);
    box-shadow: inset 2px 2px 4px var(--dark-gray), inset -1px -1px 2px var(--light-gray-1);
    border-radius: 12px;
    border: 1px solid var(--gray-shade) !important;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 140%;
    color: var(--light-gray);
}

.applicant-form {
    display: grid;
    grid-template-columns: 2.5rem 25rem;
    margin-top: 20px;
    margin-bottom: 20px;
}

.gems-pop-up {
    .modal-footer {
        position: absolute;
        bottom: 0;
        width: 100%;
    }
}

@media (min-width: 576px) {
    .mw-inherit {
        max-width: inherit !important;
    }
}