.readOnlyInputBlock {
    min-width: 200px;
}

.wrap_input {
    display: flex;
    flex-wrap: wrap;
}

.enableBtncheck {
    color: #FFF;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #94A3B8;
    padding: 5px 20px;
    background: linear-gradient(90deg, #3D1D76 0%, #653DAF 100%);
    font-weight: 400;
    float: right;
}

.disableBtncheck {
    color: #dcdcdc;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #94A3B8;
    padding: 5px 20px;
    background: #75619a;
    font-weight: 400;
    float: right;
}

.enable_cancel_btn {
    color: #1E293B;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #94A3B8;
    padding: 5px 20px;
    background-color: #FFF;
    font-weight: 700;
    float: right;
}

.disable_cancel_btn {
    color: #1E293B;
    text-align: center;
    border-radius: 8px;
    border: 1px solid #94A3B8;
    padding: 5px 20px;
    background-color: #dbdbdb;
    font-weight: 700;
    float: right;
}

textarea {
    resize: none;
}

.upload_bills_modal_body {
    max-width: 100%;
    height: 400px;
    object-fit: scale-down;
    object-position: center;
}

.expense-form {
    border-bottom: solid grey 1px;
    padding-bottom: 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.expense-form-fields {
    width: 100%;
    flex-wrap: wrap;
}

.gap-10 {
    gap: 10px
}
.gap-20 {
    gap: 20px
}

.employee_label {
    font-family: 'UniNeue-Bold', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
    color: #1E293B;
}

.empolye_search_input {
    z-index: 1;
    position: relative;
    left: -45px;
    top: 7px;
    right: 0;
}

.claim-form-div {
    position: relative;
}

.bottom-tab {
    bottom: 0;
    right: 0;
    position: absolute;
}

.plus-img {
    height: 20px;
    width: 20px;
}

.errorBlock {
    color: #FF5353;
}