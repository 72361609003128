.momentsFeedDropdown {
    width: 8.688rem;
    margin-top: 5px;
    margin-bottom: 5px;
}

.mom-feeds-rows-heading {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    line-height: 175.3%;
    color: #532F91;
    margin-bottom: 0px;
    white-space: break-spaces;
}

.corner-date {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #64748B;
}

.tata-mile-scrolling {
    background-color: #f8f9fa;
}

.moments-img {
    border-radius: 8px;
    max-width: 100%;
    max-height: 100%;
    object-fit: scale-down;
    height: 14.438rem;
}

.upcomming-milestone-card {
    height: 200px;
}

.upcoming-occasion-mom {
    height: 40% !important;
}

.cherish-title-wish-popup {
    padding-top: 10px;
    padding-left: 10px;
    margin-top: 15px;
}

.m-rem {
    margin-left: 0.8rem;
}

.milestoneCard {
    height: 19.7rem;
    margin-top: 1.5rem;
}

.upcoming-medal-logo {
    display: flex;
    justify-content: space-around;
    margin-top: 5px;
    padding-right: 45px;
}

.date,
.description {
    grid-column: 2 / 3;
    align-self: center;
}

.description span {
    opacity: .5;
}

.line {
    position: absolute;

    grid-column: 1 / 2;
    justify-self: center;

    height: 100%;
    width: 4px;
    z-index: 0;

    background-color: rgba(0, 0, 0, .1);
}

.timeline-goal-timeline {
    position: relative;
    max-width: 1200px;
    margin: 0 auto;
    width: 100%;
}

.timeline-goal-timeline::after {
    content: "";
    position: absolute;
    width: 0.2px;
    border-style: dashed;
    border-color: #d2d2df;
    border-width: 0.2px;
    background-color: #94a3b8;
    top: 49px;
    bottom: -64px;
    left: 55%;
    margin-left: -3px;
    grid-row-gap: 70px;
    row-gap: 70px;
}

.circle-text-timeline {
    left: 4.2px;
    width: 48.5px !important;
    height: 48.5px !important;
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    top: 6.8px;
    border-radius: 100%;
    font-size: 0.875rem;
    z-index: 2;
    margin-left: 30px;
    box-shadow: -2px -2px 12px #c9cbcf8a, 4px 4px 12px #c9cbcf8a;
}

.circle-text-timeline-center {
    left: 4.2px;
    width: 50.5px !important;
    height: 50.5px !important;
    font-family: "UniNeue-Regular", Sans-serif;
    font-style: normal;
    font-weight: 700;
    text-align: center;
    top: 5.8px;
    border-radius: 100%;
    font-size: 0.875rem;
    z-index: 2;
    margin-left: 30px;
    box-shadow: -2px -2px 12px #c9cbcf8a, 4px 4px 12px #c9cbcf8a;
}

.mb-2 {
    margin-bottom: 1.4rem !important;
}

.timeline-circle {
    background-color: #FFFFFF;
}

.send-popup-small-img {
    display: flex;
    justify-content: flex-start;
    gap: 20px;
    padding-bottom: 20px;
    padding-left: 20px;
    height: 6rem;
}

.textareaMom {
    box-shadow: inset 2px 2px 4px #C2CDEF, inset -1px -1px 2px #EDF2FF;
    border-radius: 12px;
    border: 1px solid #E2E8F0;
    margin: 10px !important;
    margin-right: 20px;
    height: 20vh !important;
    width: 95% !important;
}

.send-popup-image-text {
    padding-left: 20px;
}

.popup-box-bg-color {
    background-color: #00000021 !important;
}

.isSelect {
    border: solid 2px #877f7f;
    border-radius: 12px;
}

.card {
    margin: 28px 50px 28px 50px;
    height: 380px;
    width: 349px;
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0, 0, 0, .125);
    border-radius: 1.25rem;
}

.container-head {
    flex-grow: 1;
    background: linear-gradient(90deg, #653DAF 0%, #3D1D76 100%);
    border-top-left-radius: 1.25rem;
    border-top-right-radius: 1.25rem;
    display: flex;
    flex-direction: row;
    min-height: 3rem;
}

.title-text {
    color: #ffff;
    font-size: 22px !important;
    font-weight: 700 !important;
    margin-top: 11%;
    margin-bottom: 0%;
    padding-left: 8%;
    padding-top: 0%;
    line-height: 0px;
}

.mail-head {
    flex-grow: 1;
}

.mail-head-img {
    flex-grow: 1;
    height: 25px;
    width: 0px;
    margin: 2% 19px -2% 51px;
    padding-left: 0px;
    padding-top: 4px;
}

.container-mid {
    flex-grow: 8;
}

.container-bottom {
    flex-grow: 1;
    background: linear-gradient(90deg, #653DAF 0%, #3D1D76 100%);
    border-bottom-left-radius: 1.25rem;
    border-bottom-right-radius: 1.25rem;
    min-height: 3rem;
}

.selected-img {
    margin: 10px 39% 10px 39%;
    height: 5.5rem;
    width: 5.5rem;
}

.mail-bottom-img {
    flex-grow: 1;
    height: 33px;
    width: 104px;
    margin: 1% 0% -4% 70%;
    padding-left: 2px;
    padding-top: 0px;
}

.mail-text-msg {
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 0em;
    overflow: hidden scroll;
    max-height: 7rem;
}

.mail-text-msg-h {
    color: black;
    font-weight: 900;
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 0.4em;
}

.mail-text-msg-r {
    margin-left: 4%;
    margin-right: 4%;
    margin-bottom: 0em;
}

.sender-popup-card {
    position: relative;
    margin: 0 auto;
    background: #fff;
    border-radius: 15px;
    overflow: auto;
    margin-left: 30rem;
    margin-right: 30rem;
}

.mom-backscreen-key {
    width: 10%;
    margin-bottom: 10px;
}

.tata-milestones-timeline {
    flex-direction: column;
    align-items: baseline;
}

.coming-soon-milestones {
    margin: 80px;
    padding: 8px;
}

.mom-feed-data {
    overflow-y: auto;
    height: 63.6vh;
}

.upcoming-mile-inner-text {
    margin: 20px;
    text-align: center;

}

.upcoming-card-text {
    padding: 10px;
    margin-top: 15px;
}

.child-team-card-mtm {
    background: #FFFFFF;
    box-shadow: -2px -2px 12px #F1F5F9, 4px 4px 12px #E2E8F0;
    border-radius: 10px;
}

.upcoming-icon-img {
    margin-left: 60px;
}

.mtm-wraper-grid {
    display: grid;
    grid-template-columns: 4fr 1.2fr;
    grid-template-rows: 0fr 0fr;
    /* grid-auto-rows: minmax(150px, auto); */
    width: 100%;
    height: 85vh;
    grid-template-areas:
        "feeds upcoming"
        "feeds tataMotors"
        "feeds tataMotors";
    grid-gap: 24px;
}

.mom-feeds {
    grid-area: feeds;
}

.upcoming-milestone {
    grid-area: upcoming;
}

.tataMotors-milestones {
    grid-area: tataMotors;
}

@media (max-width: 768px) {
    .mtm-wraper-grid {
        grid-template-columns: 1fr;
        grid-template-rows: 2fr 1fr 1fr;
        width: 100%;
        height: 85vh;
        grid-template-areas:
            "feeds"
            "upcoming"
            "tataMotors";
    }
}

@media (max-width: 1024px) and (min-width: 769px) {
    .mtm-wraper-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 2fr;
        width: 100%;
        height: 85vh;
        grid-template-areas:
            "feeds feeds"
            "upcoming tataMotors";
    }

    .upcomming-milestone-card {
        height: 295px;
    }

    .upcoming-mile-inner-text {
        margin-top: 65px;
    }

    .sender-popup-card {
        margin-left: auto;
        margin-right: auto;
        width: 400px;
    }

    .card {
        width: 302px;
    }
}

@media (max-width: 768px) and (min-width: 320px) {
    .sender-popup-card {
        margin-left: auto;
        margin-right: auto;
        width: 300px;
    }

    .card {
        margin: 24px 17px;
        height: 310px;
        width: 268px;
        position: relative;
        display: flex;
        flex-direction: column;
        min-width: 0;
        word-wrap: break-word;
        background-color: #fff;
        background-clip: border-box;
        border: 1px solid rgba(0, 0, 0, .125);
        border-radius: 1.25rem;
    }

    .mail-bottom-img {
        width: 84px;
    }

    .mail-head-img {
        width: 25px;
        margin: 1% 19px -2% 51px;
    }
}

@media (min-width: 1025px) {
    .sender-popup-card {
        margin-left: auto;
        margin-right: auto;
        width: 450px;
    }
}

.tata-milestone-img1 {
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    border-radius: 27%;
    margin-top: 7px;
    margin-right: 9px;
}

.tata-milestone-img2 {
    width: 50px;
    height: 50px;
    cursor: pointer;
    border-radius: 50%;
}

.tata-milestone-img3 {
    width: 2.2rem;
    height: 2.2rem;
    cursor: pointer;
    border-radius: 50%;
    margin-top: 7px;
}

.center-circle {
    flex-wrap: inherit;
}