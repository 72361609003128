.edit-card1 {
    height: 100% !important;
}

.mbottom12px {
    margin-bottom: 12px !important;
}

.mLRT-employee {
    margin-top: 5px;
    margin-left: 10px;
    margin-right: 10px;
 }

.deactivate-button-left {
    width: 40%;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(174, 174, 192, 0.5);
    border-radius: 12px 0px 0px 12px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #64748B;
    cursor: pointer;
    height: 36px;
}

.w-80 {
    width: '380px' !important;
}

.deactivate-button-right {
    width: 40%;
    background: #FFFFFF;
    box-shadow: 4px 4px 4px rgba(174, 174, 192, 0.5);
    border-radius: 0px 12px 12px 0px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #64748B;
    cursor: pointer;
    height: 36px;
}

.activate-button-left {
    width: 40%;
    background: #EDF1FF;
    box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5), inset 2px 2px 4px #C2CDEF;
    border-radius: 12px 0px 0px 12px;
    height: 36px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #3D1D76;
    cursor: pointer;
}

.activate-button-right {
    width: 40%;
    background: #EDF1FF;
    box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5), inset 2px 2px 4px #C2CDEF;
    border-radius: 0px 12px 12px 0px;
    height: 36px;
    text-align: center;
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 0.813rem;
    align-items: center;
    color: #3D1D76;
    cursor: pointer;
}

.todoline {
    position: absolute;
    width: 90%;
    height: 0px;
    border: 1px solid #E4E4E4;
}

.w-100 {
    width: '100%' !important;
}

.todo-container {
    height: 11vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}

.today {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 1.188rem;
    display: flex;
    align-items: center;
    text-align: center;
    height: 1.688rem;
    color: #475569;
    position: absolute;
}

.todotitle {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.875rem;
    line-height: 1.188rem;
    align-items: center;
    color: #1E293B;
    white-space: pre-line;
}

.todocontent {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.75rem;
    line-height: 1rem;
    color: #64748B;
}

.tododate {
    /* font-family: 'UniNeue-Regular-Italic'; */
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.625rem;
    line-height: 0.875rem;

    color: #1E293B;
}

.check-icon {
    width: 1.125rem;
    height: 1.125rem;
    cursor: pointer;
}

/* .bullet-list {
    width: 12px;
    height: 12px;
    background: #C8272C;
    border-radius: 50%;
    margin-left: -1em;
    margin-top: 5px;
} */
.to-do-circle {
    width: 12px;
    height: 12px;

    background-color: red;
}

.todo-circle-div {
    height: 12px;
    width: 12px;
    border-radius: 50%;
    padding: 5px;
    margin: 5px 8px 5px 2px;
    display: flex;
}

ul {
    list-style: none;
    /* Remove default bullets */
}

ul li::before {
    content: "\2B24";
    /* Add content: \2022 is the CSS Code/unicode for a bullet */
    color: red;
    /* Change the color */
    font-weight: bold;
    /* If you want it to be bold */
    display: inline-block;
    /* Needed to add space between the bullet and the text */
    width: 1em;
    /* Also needed for space (tweak if needed) */
    margin-left: -0.9em;
    /* Also needed for space (tweak if needed) */
}

.addnewtodo {
    cursor: pointer;
}

.todo-container-emp{
    height: 44vh !important;
    overflow-y: auto;
    overflow-x: hidden;
}
@media (max-width: 1400px) {
    .todo-container {
        height: 30vh;
    }
}

@media (max-width: 1200px) {
    .todo-container {
        height: 42vh !important;
    }

    .edit-card1 {
        height: 100% !important;
    }
}

@media (max-width: 768px) {
    .todo-container {
        height: 42vh !important;
    }

    .edit-card1 {
        height: 100% !important;
    }
}