.employee-feild {
    display: block;
    width: 100%;
    height: calc(1.5em + .75rem + 2px);
    padding: .375rem .75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: .25rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}
.bottom-box{
    padding: .75rem;
}
.popup-top-part {
    padding-left: 1rem;
    padding-right: 1rem;
    padding-bottom: 0 !important;
    width: 55%;
}
.main-atribute{
    font-size: 14px;
}
.sub-atribute{
    font-size: 12px;
    font-weight: bold;
    padding-right: 2px;
}
.sub-atribute-value{
    font-size: 12px;
}
.invalid {
    border-color: red;
}

.invalid-feedback {
    color: red;
    font-size: 0.8em;
}
.ot-grid-container {
    display: grid;
    grid-template-columns: repeat(3, 1fr); /* Three equal-width columns */
    gap: 5px; /* Spacing between grid items */
}

.record {
    /* border: 1px solid #ccc;  */
    padding: 2px; /* Optional: Add padding to each record */
}

.sub-atribute {
    font-weight: bold; /* Optional: Make attribute names bold */
}


