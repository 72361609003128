.Appinfo {
    /* flex: 1; */
    align-items: center;
    justify-content: center;
    /* background-color: aqua; */
    display: flex;
    height: 100vh;
    flex-direction: column;
}

.img-info {
    height: 100px;
    width: 100px;
}