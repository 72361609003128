.wrapper-grid-emp {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    /* grid-template-rows: 1fr 1fr 1fr 1fr 1fr; */
    grid-auto-rows: minmax(150px, auto);
    grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    width: 100%;
    height: 86.5vh !important;
    grid-template-areas:
        "emp_leave emp_leave emp_doc emp_doc emp_announcement emp_announcement emp_announcement emp_announcement explore explore explore"
        "emp_leave emp_leave emp_doc emp_doc emp_announcement emp_announcement emp_announcement emp_announcement explore explore explore"
        "emp_leave emp_leave emp_doc emp_doc emp_announcement emp_announcement emp_announcement emp_announcement explore explore explore"
        "emp_cfm emp_cfm emp_cfm emp_cfm emp_announcement emp_announcement emp_announcement emp_announcement emp_todo emp_todo emp_todo"
        "emp_cfm emp_cfm emp_cfm emp_cfm emp_announcement emp_announcement emp_announcement emp_announcement emp_todo emp_todo emp_todo"
        "emp_cfm emp_cfm emp_cfm emp_cfm emp_announcement emp_announcement emp_announcement emp_announcement emp_todo emp_todo emp_todo"
        "emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps"
        "emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps emp_apps";
    grid-gap: 24px;
}


.leavecolumn {
    grid-area: emp_leave !important;
}

.documentcolumn {
    grid-area: emp_doc;
}

.performancecolumn {
    grid-area: emp_cfm;
}

.announcementcolumn {
    grid-area: emp_announcement;
}

.explorecolumn {
    grid-area: explore;
}

.todolistcolumn {
    grid-area: emp_todo;
}

.appcenterhomecolumn {
    grid-area: emp_apps;
    padding-bottom: 0px !important;
}

@media (max-width: 1300px) {
    .wrapper-grid {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr;
        /* width: 100%; */
        /* height: 185vh; */
        grid-template-areas:
            "emp_leave emp_announcement"
            "emp_doc emp_announcement"
            "explore explore"
            "emp_todo emp_todo"
            "emp_cfm emp_cfm"
            "emp_apps emp_apps";
    }

    .main-contain-child {
        height: 79% !important;
    }
}

@media (max-width: 1200px) {
    .wrapper-grid-emp {
        display: grid;
        grid-template-columns: 1fr 1fr 1fr 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr;
        grid-auto-rows: minmax(150px, auto);
        width: 100%;
        height: 156vh !important;
        grid-template-areas:
            "emp_leave emp_leave emp_announcement emp_announcement"
            "emp_doc emp_doc emp_announcement emp_announcement"
            "emp_todo emp_todo emp_cfm emp_cfm"
            "explore explore explore explore"
            "emp_apps emp_apps emp_apps emp_apps";
        grid-gap: 24px;
    }
}

@media (max-width: 860px) {
    .wrapper-grid-emp {
        height: 163vh !important;
    }
}

@media (max-width: 768px) {

    .wrapper-grid-emp {
        display: grid;
        grid-template-columns: 1fr;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        grid-auto-rows: minmax(150px, auto);
        width: 100%;
        height: 170vh !important;
        grid-template-areas:
            "emp_leave"
            "emp_doc"
            "emp_cfm"
            "emp_announcement"
            "emp_todo"
            "explore"
            "emp_apps";
        grid-gap: 24px;
    }
}

@media (max-width: 600px) {

    .appcenterhomecolumn {
        padding-right: 12px !important;
        padding-left: 12px !important;
        padding-top: 12px !important;
    }
}

@media (max-width: 700px) {
    .leavecolumn {
        position: relative;
        width: 100%;
    }
}