.edit-leave-card {
    height: 100% !important;
}

* {
    font-size: 16px;
}

.Cardtitle {
    color: #334155;
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 500;
    font-size: 1.375rem;
}

.employeeLeaveContainer {
    overflow-y: auto;
    height: 13vh;
    overflow-x: hidden;
}

.leave-box {
    font-family: "UniNeue-Regular", Sans-serif;
    color: #1E293B;
    font-weight: 400;
    height: 30px;
    width: 90%;
    padding: 6px;
    box-shadow: none;
    border-radius: 8px;
}

.totalLeave {
    font-family: 'UniNeue-bold', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 0.9rem;
    line-height: 1.375rem;
    display: flex;
    align-items: center;
    color: #1E293B;
}

.privilege-text {
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 0.7rem;
    line-height: 1rem;
    color: #1E293B;
}

.privilege-outer-color {
    background: linear-gradient(90deg, #F1F0DB 0%, rgba(241, 240, 219, 0) 92.17%);
    border-radius: 8px;
}

.casual-outer-color {
    background: linear-gradient(90deg, #E2F1DB 0%, rgba(226, 241, 219, 0) 100%);
    border-radius: 8px;
}

.sick-outer-color {
    background: linear-gradient(90deg, #F1E0DB 0%, rgba(241, 224, 219, 0) 100%);
    border-radius: 8px;
}

@media (max-width: 700px) {
    .edit-leave-card {
        margin-top: 15px;
    }
}

@media (min-width: 1381px) {
    .employeeLeaveContainer {
        height: 13vh;
    }
}

@media (max-width: 1379px) {
    .edit-leave-card {
        height: 100% !important;
    }
}

/* .circle-color {
    background: linear-gradient(225deg, #4B83D9 15.43%, #6881D7 46.89%, #8C7ED4 85.71%);
    border-radius: 50%;

    width: 50px;
    height: 50px;
    position: absolute;
    top: 56px;
    left: 35px;
}

.movetoleft {
    position: absolute;
    left: 30px;
    top:46px
} */
.circle-color-es {
    background: linear-gradient(225deg, #4B83D9 15.43%, #6881D7 46.89%, #8C7ED4 85.71%);
    border-radius: 50%;
    width: 50px;
    height: 50px;
    margin-left: auto;
}

.movetoleft-es {
    margin-left: 12px;
    margin-top: -60px;
}

.progress {
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

.space {
    width: 40%;
}