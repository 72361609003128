/* .rbc-btn-group, .rbc-toolbar ,.rbc-toolbar-label {
    display: none !important;
} */
.rbc-toolbar .rbc-toolbar-label {
    display: none;
}

.rbc-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 1.2rem;
    right: 15px;
    background-color: #F2F4FB;
    border: 0px solid #F2F4FB;
    font-size: 0.875rem;
    font-style: normal;
    box-shadow: inset 2px 2px 4px #c2cdef, inset -1px -1px 2px #edf2ff;
    font-family: "UniNeue-Regular", Sans-serif;
    font-weight: 800;
    color: #1E293B;
}

.rbc-btn-group>button:first-child:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    display: none;
}

.scroller-cal-bg {
    overflow-y: auto;
    height: 55vh;
}

.rbc-month-header {
    background: #F1F5F9;
    height: 3.125rem;
    border-radius: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #ddd;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
}

.rbc-header {
    border-bottom: 0px solid #ddd;
    ;
}

.rbc-calendar *,
.rbc-calendar *:before,
.rbc-calendar *:after {
    font-size: 1rem;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
}

.attendance-card {
    background: linear-gradient(250.32deg, #E9EAEE 13.13%, #E4E6EB 86.79%), #f5f6f9;
    background-blend-mode: soft-light, normal;
    border: 1.5px solid #FFFFFF;
    box-sizing: border-box;
    box-shadow: 0px 0px 17px rgba(255, 255, 255, 0.5), 4px 4px 8px rgba(51, 65, 85, 0.26);
    border-radius: 20px;
}

.circle-attendance-input {
    width: 200px;
    height: 70px;
    left: 508px;
    top: 377px;

    background: #F1F5F9;
    border: 1px solid #CBD5E1;
    border-radius: 20px;
}

/* 131px */
.calender-button-width {
    width: 100px;
}

.visibility-hidden {
    visibility: hidden !important;
}

.subdetail-label-light-text {
    font-family: 'UniNeue-Normal', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 1rem;
    color: #475569;
}

.big_calender_table_scroll {
    height: 55vh;
    overflow-y: auto;
}

.calendar-div {
    height: 2.275rem;
    width: 2.5rem;
    justify-content: center;
    align-items: center;
    background: #FFFFFF;
    border-radius: 12px 0px 0px 12px;
    transform: matrix(1, 0, 0, -1, 0, 0);
}

/* .rbc-ellipsis, .rbc-show-more, .rbc-row-segment .rbc-event-content, .rbc-event-label {
    display: block;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: pre-line !important;
    height: 200px !important;
    padding: 1rem;
} */
.rbc-ellipsis,
.rbc-show-more,
.rbc-row-segment .rbc-event-content,
.rbc-event-label {
    /* display: block; */
    /* overflow: hidden; */
    text-overflow: ellipsis;
    white-space: pre-line;
    height: 250px !important;
    padding-bottom: 10px !important;
    overflow-y: auto;
}

.calendar-image {
    width: 1.25rem;
    height: 1.25rem;
    background-color: #EDF1FF;
}

.list-div {
    width: 2.625rem;
    height: 2.375rem;
    display: flex;
    justify-content: center;
    align-items: center;

    background: #FFFFFF;
    /* box-shadow: 4px 4px 4px rgba(174, 174, 192, 0.5); */
    border-radius: 12px 0px 0px 12px;
    transform: matrix(-1, 0, 0, 1, 0, 0);
}

.list-image {
    width: 1.25rem;
    height: 1.25rem;
}

.main-toggle-div-attendance {
    background: #EDF1FF;
    box-shadow: 5px 5px 20px rgba(174, 174, 192, 0.5), inset 2px 2px 4px #C2CDEF;
    border-radius: 12px;
}

.mr-31px {
    margin-right: 1.938rem !important;
}

.mt-33px {
    margin-top: 33px !important;
}

.selected-bg-color {
    background: #E2E8F0;
}

.attendance-color-circle {
    width: 1.125rem;
    height: 1.125rem;
    border-radius: 50%;
}

.my-27px {
    margin-top: 1.688rem;
    margin-bottom: 1.688rem;
}

.attendance-leave-text {
    font-size: 0.75rem;
    line-height: 1rem;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    margin-left: 0.75rem;
    margin-right: 0.75rem;
}

.attendance-separator {
    border: 1px solid #CBD5E1;
    transform: rotate(90deg);
    width: 13px;
    height: 0px;
    margin-right: 1.5rem;
    margin-left: 1.5rem;
}

.selected-event:hover {
    background-color: 'red';
}

/* CustomTooltip.css */
.custom-tooltip .tooltip-inner {
    background: #CBD5E1;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    text-align: start;
    color: black;
}

.custom-tooltip .arrow::before {
    border-top-color: #CBD5E1 !important;
}