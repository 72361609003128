.enabledisablebox {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #495057;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 0.25rem;
}

.enabledisableIcon {
    width: 100%;
    padding: 0.375rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #000000;
    background-clip: padding-box;
}

.hzWidth {
    width: -webkit-fill-available;
}

.enable-disable-dashboard {
    max-height: 70vh;
    min-height: 65vh;
    overflow-y: auto;
}

.infosize {
    height: 17px;
    width: 17px;
}

.infoLabel::before {
    content: attr(data-tooltip2);
    width: max-content;
    background: #FFFFFF;
    border-radius: 14px;
    filter: drop-shadow(4px 4px 8px rgba(71, 85, 105, 0.2));
    padding: 10px;
    font-family: 'UniNeue-Regular', Sans-serif;
    align-items: center;
    font-style: normal;
    font-weight: 700;
    font-size: 0.875rem;
    line-height: 1.188rem;
    text-align: center;
    color: #3D1D76;
}


.infoLabel:hover::before,
.infoLabel:hover::after {
    --scale: 1;
}

.infoLabel::after {
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 0px solid transparent;
    align-items: center;
    border-right: 10px solid #FFFFFF;
    margin-left: 0%;
}

.infoLabel::before,
.infoLabel::after {
    --scale: 0;
    position: absolute;
    z-index: 1;
    margin-left: 7%;
    width: 58% !important;
    margin-top: -2.5%;
    transform: scale(var(--scale));
    transition: 150ms transform;
    transform-origin: bottom center;
}