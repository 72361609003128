.tab-active {
    color: #3D1D76;
    /* height: 45px;
    width: 168px; */
    background: rgba(61, 29, 118, 0.1);
    border-radius: 12px 12px 0px 0px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    border-bottom: 2px solid #3D1D76 !important;   
    padding: 10px;
    flex: none;  
    flex-grow: 0;
}
.tab-inactive:hover {
    cursor: pointer;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1.1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #64748B;
    flex: none;
    flex-grow: 0;
    margin: 0px 15px;
    padding: 5px;
    cursor: pointer;
    transition: 0.2s;
}
.tab-inactive {
    cursor: pointer;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    align-items: center;
    color: #64748B;
    flex: none;
    flex-grow: 0;
    margin: 0px 15px;
    padding: 10px;
}
.tab-actives {
    color: #3D1D76;
    /* height: 45px;
    width: 168px; */
    background: rgba(61, 29, 118, 0.1);
    border-radius: 12px 12px 0px 0px;
    font-family: 'UniNeue-Regular', Sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 1rem;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* margin: 0px 10px; */
    border-bottom: 2px solid #3D1D76 !important;   
    padding: 10px;
    flex: none;  
    flex-grow: 0;
}


.purple-radio {
    cursor: pointer;
  }
  
.purple-radio:checked {
    accent-color: #3D1D76;
}